// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
	/** primary **/
	// --ion-color-primary: #fff674;
	--color:var(--ion-color-dark) !important;
	--ion-color-primary-rgb: 56, 128, 255;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255, 255, 255;
	--ion-color-primary-shade: #3171e0;
	--ion-color-primary-tint: #4c8dff;

	/** secondary **/
	// --ion-color-secondary: #d5ff01;
	--ion-color-secondary-rgb: 61, 194, 255;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255, 255, 255;
	--ion-color-secondary-shade: #36abe0;
	--ion-color-secondary-tint: #50c8ff;

	/** tertiary **/
	// --ion-color-tertiary: #858e02;
	--ion-color-tertiary-rgb: 82, 96, 255;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255, 255, 255;
	--ion-color-tertiary-shade: #4854e0;
	--ion-color-tertiary-tint: #6370ff;

	/** success **/
	--ion-color-success: #2dd36f;
	--ion-color-success-rgb: 45, 211, 111;
	--ion-color-success-contrast: #ffffff;
	--ion-color-success-contrast-rgb: 255, 255, 255;
	--ion-color-success-shade: #28ba62;
	--ion-color-success-tint: #42d77d;

	/** warning **/
	--ion-color-warning: #ffc409;
	--ion-color-warning-rgb: 255, 196, 9;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0, 0, 0;
	--ion-color-warning-shade: #e0ac08;
	--ion-color-warning-tint: #ffca22;

	/** danger **/
	--ion-color-danger: #FF1E1E;
	--ion-color-danger-rgb: 235, 68, 90;
	--ion-light: #ffffff;
	--ion-dark: #000;
	--ion-color-danger-shade: #cf3c4f;
	--ion-color-danger-tint: #ed576b;

	/** dark **/
	// --ion-color-dark: #222428;
	--ion-color-dark-rgb: 34, 36, 40;
	--ion-color-dark-contrast: #fff0;
	--ion-color-dark-contrast-rgb: 255, 255, 255;
	--ion-color-dark-shade: #ffffff40;
	--ion-color-dark-tint: #383a3e;

	/** medium **/
	--ion-color-medium: #92949c;
	--ion-color-medium-rgb: 146, 148, 156;
	--ion-color-medium-contrast: #ffffff70;
	--ion-color-medium-contrast-rgb: 255, 255, 255;
	--ion-color-medium-shade: #808289;
	--ion-color-medium-tint: #9d9fa6;

	/** light **/
	// --ion-color-light: #FFFFFF;
	--ion-color-light-rgb: 244, 245, 248;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0, 0, 0;
	--ion-color-light-shade: #ffffffec;
	--ion-color-light-tint: #f5f6f9;

	/** fill color **/
	// --shadow-color-dark: #FFFFFF;
	// --s2: #000;
	// --t2: #000000;
	--pp: #00000060;
	--ss: #ffffff20;
	--tt: #000000c0;


	--shadow-color: 0deg 0% 0%;
--shadow-color1: 0deg 0% 45%;
--shadow-color2: 0deg 0% 85%;



--shadow-elevation-low:
01.3px 01.5px 01.7px hsl(var(--shadow-light) / 0.32),
0.5px 0.9px 3.2px -0.2px hsl(var(--shadow-light) / 0.42),
1.3px 2.5px 5.2px -0.5px hsl(var(--shadow-light) / 0.52);
--shadow-elevation-medium:
  0.3px 0.5px 0.7px hsl(var(--shadow-light) / 0.44),
  0.8px 1.5px 1.9px -0.6px hsl(var(--shadow-light) / 0.44),
  1.6px 3.1px 3.9px -1.2px hsl(var(--shadow-light) / 0.44),
  3.2px 6.4px 8px -1.9px hsl(var(--shadow-light) / 0.44),
  6.3px 12.5px 15.7px -2.5px hsl(var(--shadow-light) / 0.44);
--shadow-elevation-high:
  0.3px 0.5px 0.7px hsl(var(--shadow-light) / 0.41),
  1.7px 3.4px 4.3px -0.3px hsl(var(--shadow-light) / 0.41),
  3.1px 6.1px 7.7px -0.6px hsl(var(--shadow-light) / 0.41),
  4.7px 9.5px 11.9px -0.8px hsl(var(--shadow-light) / 0.41),
  7px 13.9px 17.5px -1.1px hsl(var(--shadow-light) / 0.41),
  10.1px 20.2px 25.4px -1.4px hsl(var(--shadow-light) / 0.41),
  14.4px 28.8px 36.2px -1.7px hsl(var(--shadow-light) / 0.41),
  20.2px 40.4px 50.8px -1.9px hsl(var(--shadow-light) / 0.41),
  27.8px 55.6px 69.9px -2.2px hsl(var(--shadow-light) / 0.41),
  37.5px 75px 94.3px -2.5px hsl(var(--shadow-light) / 0.41);

--shadow-mid:
0.3px 0.3px 0.6px hsl(var(--shadow-light) / 0),
1.4px 1.6px 3.2px hsl(var(--shadow-light) / 0.05),
2.5px 2.8px 5.6px hsl(var(--shadow-light) / 0.11),
4.2px 4.8px 9.6px hsl(var(--shadow-light) / 0.16),
7.3px 8.3px 16.6px hsl(var(--shadow-light) / 0.21);

--shadow-diffuse:
0.5px 0.2px 0.8px hsl(var(--shadow-light) / 0),
2.8px 1.1px 4.5px hsl(var(--shadow-light) / 0.01),
4.9px 1.9px 7.9px hsl(var(--shadow-light) / 0.02),
6.9px 2.7px 11.1px hsl(var(--shadow-light) / 0.03),
9px 3.6px 14.5px hsl(var(--shadow-light) / 0.04),
11.6px 4.6px 18.7px hsl(var(--shadow-light) / 0.05),
15px 5.9px 24.2px hsl(var(--shadow-light) / 0.06),
19.3px 7.6px 31.1px hsl(var(--shadow-light) / 0.07),
24.8px 9.8px 40px hsl(var(--shadow-light) / 0.08),
31.8px 12.5px 51.3px hsl(var(--shadow-light) / 0.1);

--shadow-l:
0.8px 0.5px 0.7px hsl(var(--shadow-light) / 0.31),
1.1px 0.8px 1px -2.5px hsl(var(--shadow-light) / 0.21),
3.8px 2.6px 3.5px -5px hsl(var(--shadow-light) / 0.1);
--shadow-m:
0.8px 0.5px 0.7px hsl(var(--shadow-light) / 0.26),
1px 0.7px 0.9px -1.2px hsl(var(--shadow-light) / 0.21),
3px 2px 2.7px -2.5px hsl(var(--shadow-light) / 0.16),
8.4px 5.7px 7.6px -3.7px hsl(var(--shadow-light) / 0.11),
18.8px 12.8px 17.1px -5px hsl(var(--shadow-light) / 0.05);

--shadow-m-anim:
		2px -2px 2px -1px hsl(var(--shadow-light) / 0.15),
		-4px 4px 4px -2px hsl(var(--shadow-light) / 0.09),
		6px 6px 6px -3px hsl(var(--shadow-light) / 0.07),
		8px 8px 8px -4px hsl(var(--shadow-light) / 0.05),
		10px 10px 10px -5px hsl(var(--shadow-light) / 0.02);

--shadow-h:
0.9px 0.6px 0.8px -0.6px hsl(var(--shadow-light) / 0.22),
2px 1.3px 1.8px -1.1px hsl(var(--shadow-light) / 0.2),
4.9px 3.3px 4.4px -1.7px hsl(var(--shadow-light) / 0.17),
10.6px 7.2px 9.6px -2.2px hsl(var(--shadow-light) / 0.15),
20px 13.5px 18.1px -2.8px hsl(var(--shadow-light) / 0.12),
34px 23px 30.8px -3.3px hsl(var(--shadow-light) / 0.1),
53.5px 36.3px 48.5px -3.9px hsl(var(--shadow-light) / 0.07),
79.5px 53.9px 72px -4.4px hsl(var(--shadow-light) / 0.05),
112.9px 76.5px 102.3px -5px hsl(var(--shadow-light) / 0.02);

--shadow-c:
0.3px 0.3px 0.6px hsl(var(--shadow-color-light) / 0),
1.4px 1.6px 3.2px hsl(var(--shadow-color-light) / 0.05),
2.5px 2.8px 5.6px hsl(var(--shadow-color-light) / 0.11),
4.2px 4.8px 9.6px hsl(var(--shadow-color-light) / 0.16),
7.3px 8.3px 16.6px hsl(var(--shadow-color-light) / 0.21);

--shadow-c-l:
0.3px 0.3px 0.6px hsl(var(--shadow-color-light) / 0),
1.4px 1.6px 3.2px hsl(var(--shadow-color-light) / 0.05),
2.5px 2.8px 5.6px hsl(var(--shadow-color-light) / 0.11),
4.2px 4.8px 9.6px hsl(var(--shadow-color-light) / 0.16),
7.3px 8.3px 16.6px hsl(var(--shadow-color-light) / 0.21);

--shadow-h2:
0.9px 0.6px 0.8px -0.6px hsl(var(--shadow-light) / 0.1),
  2px 1.3px 1.8px -1.1px hsl(var(--shadow-light) / 0.1),
  4.9px 3.3px 4.4px -1.7px hsl(var(--shadow-light) / 0.1),
  10.6px 7.2px 9.6px -2.2px hsl(var(--shadow-light) / 0.1),
  20px 13.5px 18.1px -2.8px hsl(var(--shadow-light) / 0.1),
  34px 23px 30.8px -8.3px hsl(var(--shadow-light) / 0.09),
  30.5px 36.3px 48.5px -1.9px hsl(var(--shadow-light) / 0.07),
  80.5px 30.9px 20px -4.4px hsl(var(--shadow-light) / 0.05),
  102.9px 60.5px 12.3px -50px hsl(var(--shadow-light) / 0.04);

--shadow-m2:
2px 0.6px 0.8px -0.6px hsl(var(--shadow-light) / 0.1),
  2px 1.3px 1.8px -1.1px hsl(var(--shadow-light) / 0.1),
  4.9px 3.3px 4.4px -1.7px hsl(var(--shadow-light) / 0.1),
  10.6px 7.2px 9.6px -2.2px hsl(var(--shadow-light) / 0.1),
  20px 13.5px 18.1px -2.8px hsl(var(--shadow-light) / 0.1),
  34px 23px 30.8px -8.3px hsl(var(--shadow-light) / 0.09),
  3.5px 36.3px 48.5px -1.9px hsl(var(--shadow-light) / 0.05),
  8.5px 3.9px 20px -4.4px hsl(var(--shadow-light) / 0.02),
  12.9px 6.5px 12.3px -50px hsl(var(--shadow-light) / 0.01);
  --shadow-md:
  0px 0px 1px -0.5px hsl(var(--shadow-light) / 0.26),0px 0px 0px 0px hsl(var(--shadow-light) / 0.21),1px 1px 1.3px -1.5px hsl(var(--shadow-light) / 0.16),4px 2.5px 3.6px -1.7px hsl(var(--shadow-light) / 0.11),8.8px 6.8px 8.1px -2px hsl(var(--shadow-light) / 0.05);
  --shadow-side:
0px 0px 1px -0.5px hsl(var(--shadow-light) / 0.13),0px 0px 0px 0px hsl(var(--shadow-light) / 0.11),1px 1px 1.3px -1.5px hsl(var(--shadow-light) / 0.08),4px 2.5px 3.6px -1.7px hsl(var(--shadow-light) / 0.05),8.8px 6.8px 8.1px -2px hsl(var(--shadow-light) / 0.025);


--shadow-m-small: -2px 2px 2px -1px hsl(var(--shadow-light) / 0.15),
2px -2px 2px -1px hsl(var(--shadow-light) / 0.09),
4px 4px 4px -2px hsl(var(--shadow-light) / 0.07),
4px 4px 4px -2px hsl(var(--shadow-light) / 0.05),
6px 6px 6px -3px hsl(var(--shadow-light) / 0.02),
6px 6px 6px -15px hsl(var(--shadow-light) / 0.09),
8px 8px 8px -20px hsl(var(--shadow-light) / 0.07),
-8px -8px 8px -20px hsl(var(--shadow-light) / 0.05),
10px 10px 10px -10px hsl(var(--shadow-light) / 0.02);

--shadow-card:0rem 0rem 1rem -0.5rem hsl(var(--shadow-light) / 0.13),0rem 0rem 0rem 0rem hsl(var(--shadow-light) / 0.11),1rem 1rem 1.3rem -15rem hsl(var(--shadow-light) / 0.08),4rem 2.5rem 3.6rem -17rem hsl(var(--shadow-light) / 0.05),8.8rem 6.8rem 8.1rem -5rem hsl(var(--shadow-light) / 0.025);
--shadow-side2: 0rem 0rem 1rem -0.5rem hsl(var(--shadow-light) / 0.13),0rem 0rem 0rem 0rem hsl(var(--shadow-light) / 0.11),1rem 1rem 1.3rem -1.5rem hsl(var(--shadow-light) / 0.08),4rem 2.5rem 3.6rem -1.7rem hsl(var(--shadow-light) / 0.05),8.8rem 6.8rem 8.1rem -2rem hsl(var(--shadow-light) / 0.025);

		  --shadow-h3: 0.3px 0.5px 0.7px hsl(var(--shadow-light) / 0.1), 1.7px 3.4px 4.3px -0.3px hsl(var(--shadow-light) / 0.1), 3.1px 6.1px 7.7px -0.6px hsl(var(--shadow-light) / 0.31), 4.7px 9.5px 11.9px -0.8px hsl(var(--shadow-light) / 0.11), 7px 13.9px 17.5px -1.1px hsl(var(--shadow-light) / 0.31), 10.1px 20.2px 25.4px -1.4px hsl(var(--shadow-light) / 0.11), 14.4px 28.8px 36.2px -1.7px hsl(var(--shadow-light) / 0.31), 20.2px 40.4px 50.8px -1.9px hsl(var(--shadow-light) / 0.21), 27.8px 55.6px 69.9px -2.2px hsl(var(--shadow-light) / 0.31), 37.5px 75px 94.3px -2.5px hsl(var(--shadow-light) / 0.21);

		  --shadow-l1:       0.6px 0.7px 0.7px hsl(var(--shadow-light) / 1) inset,
		  0.8px 1px 1px -2.5px hsl(var(--shadow-light) / 0.69) inset,
		  2.8px 3.5px 3.4px -5px hsl(var(--shadow-light) / 0.35) inset;

		  --shadow-l2:1px  1px  1px  0px hsl(var(--shadow-light) / 0.1) inset,
		  2px  2px  2px  0px hsl(var(--shadow-light) / 0.9) inset,
		  -3px  3px  6px  0px hsl(var(--shadow-light) / 0.07) inset,
		  2px  2px  8px  2px hsl(var(--shadow-light) / 0.05) inset;

		  --shadow-l3: 1px  1px  1px  0px hsl(var(--shadow-light) / 0.1) inset,
		  2px  2px  2px  0px hsl(var(--shadow-light) / 0.08) inset,
		  -3px  3px  6px  0px hsl(var(--shadow-light) / 0.05) inset,
		  2px  2px  8px  2px hsl(var(--shadow-light) / 0.01) inset;




		  --shadow-diffuse-c:
		  0.5px 0.2px 0.8px hsl(var(--shadow-color-light) / 0),
		  2.8px 1.1px 4.5px hsl(var(--shadow-color-light) / 0.01),
		  4.9px 1.9px 7.9px hsl(var(--shadow-color-light) / 0.02),
		  6.9px 2.7px 11.1px hsl(var(--shadow-color-light) / 0.03),
		  9px 3.6px 14.5px hsl(var(--shadow-color-light) / 0.04),
		  11.6px 4.6px 18.7px hsl(var(--shadow-color-light) / 0.05),
		  15px 5.9px 24.2px hsl(var(--shadow-color-light) / 0.06),
		  19.3px 7.6px 31.1px hsl(var(--shadow-color-light) / 0.07),
		  24.8px 9.8px 40px hsl(var(--shadow-color-light) / 0.08),
		  31.8px 12.5px 51.3px hsl(var(--shadow-color-light) / 0.1);
		  
		  --shadow-l-c:
		  0.8px 0.5px 0.7px hsl(var(--shadow-color-light) / 0.31),
		  1.1px 0.8px 1px -2.5px hsl(var(--shadow-color-light) / 0.21),
		  3.8px 2.6px 3.5px -5px hsl(var(--shadow-color-light) / 0.1);
		  --shadow-m-c:
		  0.8px 0.5px 0.7px hsl(var(--shadow-color-light) / 0.26),
		  1px 0.7px 0.9px -1.2px hsl(var(--shadow-color-light) / 0.21),
		  3px 2px 2.7px -2.5px hsl(var(--shadow-color-light) / 0.16),
		  8.4px 5.7px 7.6px -3.7px hsl(var(--shadow-color-light) / 0.11),
		  18.8px 12.8px 17.1px -5px hsl(var(--shadow-color-light) / 0.05),
		  36.4px 20.7px 25.6px -10px hsl(var(--shadow-color-light) / 0.11);

		--shadow-highlight:
		2px 2px 11px -0.7px hsl(var(--shadowhighlight) / 0.29), 4px 4px 13.4px -1.4px hsl(var(--shadowhighlight) / 0.19), 6px 6px 19.5px -2.1px hsl(var(--shadowhighlight) / 0.13), 8px 8px 12.6px -2.9px hsl(var(--shadowhighlight) / 0.11), -16px 16px 14.5px -3.6px hsl(var(--shadowhighlight) / 0.08), -24px 24px 17.1px -4.3px hsl(var(--shadowhighlight) / 0.05), -32px 32px 21.5px -0.5px hsl(var(--shadowhighlight) / 0.03);

		--shadow-svg2:  drop-shadow(0.3px 0.5px 0.7px hsl(var(--shadow-light) / 0.1))
						drop-shadow(0.5px 0.7px 0.9px hsl(var(--shadow-light) / 0.15))
						drop-shadow(0.7px 0.9px 1.1px hsl(var(--shadow-light) / 0.2))
						drop-shadow(0.9px 1.1px 1.3px hsl(var(--shadow-light) / 0.25))
						drop-shadow(1.1px 1.3px 1.5px hsl(var(--shadow-light) / 0.3))
						drop-shadow(1.3px 1.5px 1.7px hsl(var(--shadow-light) / 0.35))
						drop-shadow(1.5px 1.7px 1.9px hsl(var(--shadow-light) / 0.4));

		--shadow-svg1: drop-shadow(0.3px 0.3px 0.6px hsl(var(--shadow-color-light) / 0))
		drop-shadow(1.4px 1.6px 3.2px hsl(var(--shadow-color-light) / 0.05))
		drop-shadow(2.5px 2.8px 5.6px hsl(var(--shadow-color-light) / 0.11))
		drop-shadow(4.2px 4.8px 9.6px hsl(var(--shadow-color-light) / 0.16))
		drop-shadow(7.3px 8.3px 16.6px hsl(var(--shadow-color-light) / 0.21));

		--shadow-svg01: 
		drop-shadow(-2px 1px 1px hsl(var(--shadow-color-light) / 0.5)) drop-shadow(2px -1px 1px hsl(var(--shadow-color-light) /0.5))
		drop-shadow(-2px 1px 1px hsl(var(--shadow-color-light) / 0.5)) drop-shadow(2px -1px 1px hsl(var(--shadow-color-light) /0.5))
		drop-shadow(-2px 1px 1px hsl(var(--shadow-color-light) / 0.5)) drop-shadow(2px -1px 1px hsl(var(--shadow-color-light) /0.5))
		drop-shadow(-2px 1px 1px hsl(var(--shadow-color-light) / 0.5)) drop-shadow(2px -1px 1px hsl(var(--shadow-color-light) /0.5));

		--shadow-svg:drop-shadow(0.5px 0.2px 0.8px hsl(var(--shadow-color-light) / 0))
		drop-shadow(2.8px 1.1px 4.5px hsl(var(--shadow-color-light) / 0.01))
		drop-shadow(4.9px 1.9px 7.9px hsl(var(--shadow-color-light) / 0.02))
		drop-shadow(6.9px 2.7px 11.1px hsl(var(--shadow-color-light) / 0.03))
		drop-shadow(9px 3.6px 14.5px hsl(var(--shadow-color-light) / 0.04))
		drop-shadow(11.6px 4.6px 18.7px hsl(var(--shadow-color-light) / 0.05))
		drop-shadow(15px 5.9px 24.2px hsl(var(--shadow-color-light) / 0.06))
		drop-shadow(19.3px 7.6px 31.1px hsl(var(--shadow-color-light) / 0.07))
		drop-shadow(24.8px 9.8px 40px hsl(var(--shadow-color-light) / 0.08))
		drop-shadow(31.8px 12.5px 51.3px hsl(var(--shadow-color-light) / 0.1));


		--shadow-low-nutri:
		5px -1px 10px -10px var(--low-nutri),
		4px -2px 12px -10px var(--low-nutri),
		3px -3px 14px -10px var(--low-nutri),
		2px -4px 16px -10px var(--low-nutri),
		1px 5px 18px -10px var(--low-nutri);

		--shadow-avg-nutri:
		5px -1px 10px -10px var(--avg-nutri),
		4px -2px 12px -10px var(--avg-nutri),
		3px -3px 14px -10px var(--avg-nutri),
		2px -4px 16px -10px var(--avg-nutri),
		1px 5px 18px -10px var(--avg-nutri);

		--shadow-high-nutri:
		5px -1px 10px -10px var(--high-nutri),
		4px -2px 12px -10px var(--high-nutri),
		3px -3px 14px -10px var(--high-nutri),
		2px -4px 16px -10px var(--high-nutri),
		1px 5px 18px -10px var(--high-nutri);

		--shadow-color-s:
		0.5px 0.2px 0.8px hsl(var(--shadowhighlight) / 0),
		2.8px 1.1px 4.5px hsl(var(--shadowhighlight) / 0.01),
		4.9px 1.9px 7.9px hsl(var(--shadowhighlight) / 0.02),
		6.9px 2.7px 11.1px hsl(var(--shadowhighlight) / 0.03),
		9px 3.6px 14.5px hsl(var(--shadowhighlight) / 0.04),
		11.6px 4.6px 18.7px hsl(var(--shadowhighlight) / 0.05),
		15px 5.9px 24.2px hsl(var(--shadowhighlight) / 0.06),
		19.3px 7.6px 31.1px hsl(var(--shadowhighlight) / 0.07),
		24.8px 9.8px 40px hsl(var(--shadowhighlight) / 0.08),
		31.8px 12.5px 51.3px hsl(var(--shadowhighlight) / 0.1);

		--shadow-high2: -20px 10px 80px -0px hsl(var(--shadowhighlight) / 0.5),
		-50px 0px 30px -50px hsl(var(--shadowhighlight) / 0.2),
		0px -10px 50px -0px hsl(var(--shadowhighlight) / 0.3),
		50px 0px 60px -55px hsl(var(--shadowhighlight) / 1),
		0px -40px 80px -0px hsl(var(--shadowhighlight) / 0.41),
		-10px 0px 10px -35px hsl(var(--shadowhighlight) / 1),
		0px 10px 12px -40px hsl(var(--shadowhighlight) / 1);

		

}



// @media (prefers-color-scheme: dark) 
:root{
	/*
	 * Dark Colors
	 * -------------------------------------------
	 */

	body.dark {
		--color:var(--ion-color-dark) !important;
		// --ion-color-primary: #42ffb0;
		--ion-color-primary-rgb: 66, 140, 255;
		--ion-color-primary-contrast: #ffffff;
		--ion-color-primary-contrast-rgb: 255, 255, 255;
		--ion-color-primary-shade: #3a7be0;
		--ion-color-primary-tint: #5598ff;

		// --ion-color-secondary: #50ff67;
		--ion-color-secondary-rgb: 80, 200, 255;
		--ion-color-secondary-contrast: #ffffff;
		--ion-color-secondary-contrast-rgb: 255, 255, 255;
		--ion-color-secondary-shade: #46b0e0;
		--ion-color-secondary-tint: #62ceff;

		// --ion-color-tertiary: #83ff64;
		--ion-color-tertiary-rgb: 106, 100, 255;
		--ion-color-tertiary-contrast: #ffffff;
		--ion-color-tertiary-contrast-rgb: 255, 255, 255;
		--ion-color-tertiary-shade: #5d58e0;
		--ion-color-tertiary-tint: #7974ff;

		--ion-color-success: #2fdf75;
		--ion-color-success-rgb: 47, 223, 117;
		--ion-color-success-contrast: #000000;
		--ion-color-success-contrast-rgb: 0, 0, 0;
		--ion-color-success-shade: #29c467;
		--ion-color-success-tint: #44e283;

		--ion-color-warning: #ffd534;
		--ion-color-warning-rgb: 255, 213, 52;
		--ion-color-warning-contrast: #000000;
		--ion-color-warning-contrast-rgb: 0, 0, 0;
		--ion-color-warning-shade: #e0bb2e;
		--ion-color-warning-tint: #ffd948;

		--ion-color-danger: #ff4961;
		--ion-color-danger-rgb: 255, 73, 97;
		--ion-light: #000;
		--ion-dark: #fff;
		--ion-color-danger-shade: #e04055;
		--ion-color-danger-tint: #ff5b71;

		// --ion-color-dark: #f4f5f8;
		--ion-color-dark-rgb: 244, 245, 248;
		--ion-color-dark-contrast: #0000;
		--ion-color-dark-contrast-rgb: 0, 0, 0;
		--ion-color-dark-shade: #00000040;
		--ion-color-dark-tint: #f5f6f9;

		--ion-color-medium: #989aa2;
		--ion-color-medium-rgb: 152, 154, 162;
		--ion-color-medium-contrast: #00000070;
		--ion-color-medium-contrast-rgb: 0, 0, 0;
		--ion-color-medium-shade: #86888f;
		--ion-color-medium-tint: #a2a4ab;

		// --ion-color-light: #222428;
		--ion-color-light-rgb: 34, 36, 40;
		--ion-color-light-contrast: #ffffff;
		--ion-color-light-contrast-rgb: 255, 255, 255;
		--ion-color-light-shade: #1e2023f7;
		--ion-color-light-tint: #383a3e;

			
		// --shadow-color-dark: #000;
		// --s2: 244, 245, 248;
		// --t2: #fff;
		--pp: #ffffff60;
		--ss: #00000020;
		--tt: #ffffffc0;

		--shadow-color: 0deg 0% 5%;
		--shadow-color1: var(--shadow-light);
		--shadow-color2: var(--shadow-light);


		--shadow-elevation-low:
		01.3px 01.5px 01.7px hsl(var(--shadow-light) / 0.32),
		0.5px 0.9px 3.2px -0.2px hsl(var(--shadow-light) / 0.42),
		1.3px 2.5px 5.2px -0.5px hsl(var(--shadow-light) / 0.52);
		--shadow-elevation-medium:
		  0.3px 0.5px 0.7px hsl(var(--shadow-light) / 0.44),
		  0.8px 1.5px 1.9px -0.6px hsl(var(--shadow-light) / 0.44),
		  1.6px 3.1px 3.9px -1.2px hsl(var(--shadow-light) / 0.44),
		  3.2px 6.4px 8px -1.9px hsl(var(--shadow-light) / 0.44),
		  6.3px 12.5px 15.7px -2.5px hsl(var(--shadow-light) / 0.44);
		--shadow-elevation-high:
		  0.3px 0.5px 0.7px hsl(var(--shadow-light) / 0.41),
		  1.7px 3.4px 4.3px -0.3px hsl(var(--shadow-light) / 0.41),
		  3.1px 6.1px 7.7px -0.6px hsl(var(--shadow-light) / 0.41),
		  4.7px 9.5px 11.9px -0.8px hsl(var(--shadow-light) / 0.41),
		  7px 13.9px 17.5px -1.1px hsl(var(--shadow-light) / 0.41),
		  10.1px 20.2px 25.4px -1.4px hsl(var(--shadow-light) / 0.41),
		  14.4px 28.8px 36.2px -1.7px hsl(var(--shadow-light) / 0.41),
		  20.2px 40.4px 50.8px -1.9px hsl(var(--shadow-light) / 0.41),
		  27.8px 55.6px 69.9px -2.2px hsl(var(--shadow-light) / 0.41),
		  37.5px 75px 94.3px -2.5px hsl(var(--shadow-light) / 0.41);
	  
		  --shadow-md:
		  0px 0px 1px -0.5px hsl(var(--shadow-light) / 0.26),0px 0px 0px 0px hsl(var(--shadow-light) / 0.21),1px 1px 1.3px -1.5px hsl(var(--shadow-light) / 0.16),4px 2.5px 3.6px -1.7px hsl(var(--shadow-light) / 0.11),8.8px 6.8px 8.1px -2px hsl(var(--shadow-light) / 0.05);
		  --shadow-side:
	  0px 0px 1px -0.5px hsl(var(--shadow-light) / 0.13),0px 0px 0px 0px hsl(var(--shadow-light) / 0.11),1px 1px 1.3px -1.5px hsl(var(--shadow-light) / 0.08),4px 2.5px 3.6px -1.7px hsl(var(--shadow-light) / 0.05),8.8px 6.8px 8.1px -2px hsl(var(--shadow-light) / 0.025);

		  --shadow-h2: 0.3px 0.5px 0.7px hsl(var(--shadow-light) / 0.1), 1.7px 3.4px 4.3px -0.3px hsl(var(--shadow-light) / 0.1), 3.1px 6.1px 7.7px -0.6px hsl(var(--shadow-light) / 0.31), 4.7px 9.5px 11.9px -0.8px hsl(var(--shadow-light) / 0.11), 7px 13.9px 17.5px -1.1px hsl(var(--shadow-light) / 0.31), 10.1px 20.2px 25.4px -1.4px hsl(var(--shadow-light) / 0.11), 14.4px 28.8px 36.2px -1.7px hsl(var(--shadow-light) / 0.31), 20.2px 40.4px 50.8px -1.9px hsl(var(--shadow-light) / 0.21), 27.8px 55.6px 69.9px -2.2px hsl(var(--shadow-light) / 0.31), 37.5px 75px 94.3px -2.5px hsl(var(--shadow-light) / 0.21);

		  --shadow-h3: 0.3px 0.5px 0.7px hsl(var(--shadow-light) / 0.1), 1.7px 3.4px 4.3px -0.3px hsl(var(--shadow-light) / 0.1), 3.1px 6.1px 7.7px -0.6px hsl(var(--shadow-light) / 0.31), 4.7px 9.5px 11.9px -0.8px hsl(var(--shadow-light) / 0.11), 7px 13.9px 17.5px -1.1px hsl(var(--shadow-light) / 0.31), 10.1px 20.2px 25.4px -1.4px hsl(var(--shadow-light) / 0.11), 14.4px 28.8px 36.2px -1.7px hsl(var(--shadow-light) / 0.31), 20.2px 40.4px 50.8px -1.9px hsl(var(--shadow-light) / 0.21), 27.8px 55.6px 69.9px -2.2px hsl(var(--shadow-light) / 0.31), 37.5px 75px 94.3px -2.5px hsl(var(--shadow-light) / 0.21);
		--shadow-mid:
		0.3px 0.3px 0.6px hsl(var(--shadow-light) / 0),
		1.4px 1.6px 3.2px hsl(var(--shadow-light) / 0.05),
		2.5px 2.8px 5.6px hsl(var(--shadow-light) / 0.11),
		4.2px 4.8px 9.6px hsl(var(--shadow-light) / 0.16),
		7.3px 8.3px 16.6px hsl(var(--shadow-light) / 0.21);
	  
		--shadow-diffuse:
		0.5px 0.2px 0.8px hsl(var(--shadow-light) / 0),
		2.8px 1.1px 4.5px hsl(var(--shadow-light) / 0.01),
		4.9px 1.9px 7.9px hsl(var(--shadow-light) / 0.02),
		6.9px 2.7px 11.1px hsl(var(--shadow-light) / 0.03),
		9px 3.6px 14.5px hsl(var(--shadow-light) / 0.04),
		11.6px 4.6px 18.7px hsl(var(--shadow-light) / 0.05),
		15px 5.9px 24.2px hsl(var(--shadow-light) / 0.06),
		19.3px 7.6px 31.1px hsl(var(--shadow-light) / 0.07),
		24.8px 9.8px 40px hsl(var(--shadow-light) / 0.08),
		31.8px 12.5px 51.3px hsl(var(--shadow-light) / 0.1);
	  
		--shadow-l:
		0.8px 0.5px 0.7px hsl(var(--shadow-light) / 0.31),
		1.1px 0.8px 1px -2.5px hsl(var(--shadow-light) / 0.21),
		3.8px 2.6px 3.5px -5px hsl(var(--shadow-light) / 0.1);
	  --shadow-m:
		0.8px 0.5px 0.7px hsl(var(--shadow-light) / 0.26),
		1px 0.7px 0.9px -1.2px hsl(var(--shadow-light) / 0.21),
		3px 2px 2.7px -2.5px hsl(var(--shadow-light) / 0.16),
		8.4px 5.7px 7.6px -3.7px hsl(var(--shadow-light) / 0.11),
		18.8px 12.8px 17.1px -5px hsl(var(--shadow-light) / 0.05);

		--shadow-m-anim:
		2px -2px 2px -1px hsl(var(--shadow-light) / 0.15),
		-4px 4px 4px -2px hsl(var(--shadow-light) / 0.09),
		6px 6px 6px -3px hsl(var(--shadow-light) / 0.07),
		8px 8px 8px -4px hsl(var(--shadow-light) / 0.05),
		10px 10px 10px -5px hsl(var(--shadow-light) / 0.02);

	  --shadow-h:
		0.9px 0.6px 0.8px -0.6px hsl(var(--shadow-light) / 0.22),
		2px 1.3px 1.8px -1.1px hsl(var(--shadow-light) / 0.2),
		4.9px 3.3px 4.4px -1.7px hsl(var(--shadow-light) / 0.17),
		10.6px 7.2px 9.6px -2.2px hsl(var(--shadow-light) / 0.15),
		20px 13.5px 18.1px -2.8px hsl(var(--shadow-light) / 0.12),
		34px 23px 30.8px -3.3px hsl(var(--shadow-light) / 0.1),
		53.5px 36.3px 48.5px -3.9px hsl(var(--shadow-light) / 0.07),
		79.5px 53.9px 72px -4.4px hsl(var(--shadow-light) / 0.05),
		112.9px 76.5px 102.3px -5px hsl(var(--shadow-light) / 0.02);
	  
		--shadow-c:
		0.3px 0.3px 0.6px hsl(var(--shadow-color-light) / 0),
		1.4px 1.6px 3.2px hsl(var(--shadow-color-light) / 0.05),
		2.5px 2.8px 5.6px hsl(var(--shadow-color-light) / 0.11),
		4.2px 4.8px 9.6px hsl(var(--shadow-color-light) / 0.16),
		7.3px 8.3px 16.6px hsl(var(--shadow-color-light) / 0.21);
		
		--shadow-c-l:
		0.3px 0.3px 0.6px hsl(var(--shadow-color-light) / 0),
		1.4px 1.6px 3.2px hsl(var(--shadow-color-light) / 0.05),
		2.5px 2.8px 5.6px hsl(var(--shadow-color-light) / 0.11),
		4.2px 4.8px 9.6px hsl(var(--shadow-color-light) / 0.16),
		7.3px 8.3px 16.6px hsl(var(--shadow-color-light) / 0.21);
		

		--shadow-svg2:  drop-shadow(0.3px 0.5px 0.7px hsl(var(--shadow-light) / 0.1))
						drop-shadow(0.5px 0.7px 0.9px hsl(var(--shadow-light) / 0.15))
						drop-shadow(0.7px 0.9px 1.1px hsl(var(--shadow-light) / 0.2))
						drop-shadow(0.9px 1.1px 1.3px hsl(var(--shadow-light) / 0.25))
						drop-shadow(1.1px 1.3px 1.5px hsl(var(--shadow-light) / 0.3))
						drop-shadow(1.3px 1.5px 1.7px hsl(var(--shadow-light) / 0.35))
						drop-shadow(1.5px 1.7px 1.9px hsl(var(--shadow-light) / 0.4));

		--shadow-svg: 0.3px 0.5px 0.7px hsl(var(--shadow-light) / 0.1);

		--shadow-diffuse-c:
		0.5px 0.2px 0.8px hsl(var(--shadow-color-light) / 0),
		2.8px 1.1px 4.5px hsl(var(--shadow-color-light) / 0.01),
		4.9px 1.9px 7.9px hsl(var(--shadow-color-light) / 0.02),
		6.9px 2.7px 11.1px hsl(var(--shadow-color-light) / 0.03),
		9px 3.6px 14.5px hsl(var(--shadow-color-light) / 0.04),
		11.6px 4.6px 18.7px hsl(var(--shadow-color-light) / 0.05),
		15px 5.9px 24.2px hsl(var(--shadow-color-light) / 0.06),
		19.3px 7.6px 31.1px hsl(var(--shadow-color-light) / 0.07),
		24.8px 9.8px 40px hsl(var(--shadow-color-light) / 0.08),
		31.8px 12.5px 51.3px hsl(var(--shadow-color-light) / 0.1);
		
		--shadow-l-c:
		0.8px 0.5px 0.7px hsl(var(--shadow-color-light) / 0.31),
		1.1px 0.8px 1px -2.5px hsl(var(--shadow-color-light) / 0.21),
		3.8px 2.6px 3.5px -5px hsl(var(--shadow-color-light) / 0.1);

		--shadow-m-c:
		0.8px 0.5px 0.7px hsl(var(--shadow-color-light) / 0.26),
		1px 0.7px 0.9px -1.2px hsl(var(--shadow-color-light) / 0.21),
		3px 2px 2.7px -2.5px hsl(var(--shadow-color-light) / 0.16),
		8.4px 5.7px 7.6px -3.7px hsl(var(--shadow-color-light) / 0.11),
		18.8px 12.8px 17.1px -5px hsl(var(--shadow-color-light) / 0.05);

		--shadow-highlight:
		2px 2px 11px -0.7px hsl(var(--shadowhighlight) / 0.29), 4px 4px 13.4px -1.4px hsl(var(--shadowhighlight) / 0.19), 6px 6px 19.5px -2.1px hsl(var(--shadowhighlight) / 0.13), 8px 8px 12.6px -2.9px hsl(var(--shadowhighlight) / 0.11), -16px 16px 14.5px -3.6px hsl(var(--shadowhighlight) / 0.08), -24px 24px 17.1px -4.3px hsl(var(--shadowhighlight) / 0.05), -32px 32px 21.5px -0.5px hsl(var(--shadowhighlight) / 0.03);



		--shadow-m-small: -2px 2px 2px -1px hsl(var(--shadow-light) / 0.15),
		2px -2px 2px -1px hsl(var(--shadow-light) / 0.09),
		4px 4px 4px -2px hsl(var(--shadow-light) / 0.07),
		4px 4px 4px -2px hsl(var(--shadow-light) / 0.05),
		6px 6px 6px -3px hsl(var(--shadow-light) / 0.02),
		6px 6px 6px -15px hsl(var(--shadow-light) / 0.09),
		8px 8px 8px -20px hsl(var(--shadow-light) / 0.07),
		-8px -8px 8px -20px hsl(var(--shadow-light) / 0.05),
		10px 10px 10px -10px hsl(var(--shadow-light) / 0.02);
	
		--shadow-card:0rem 0rem 1rem -0.5rem hsl(var(--shadow-light) / 0.13),0rem 0rem 0rem 0rem hsl(var(--shadow-light) / 0.11),1rem 1rem 1.3rem -15rem hsl(var(--shadow-light) / 0.08),4rem 2.5rem 3.6rem -17rem hsl(var(--shadow-light) / 0.05),8.8rem 6.8rem 8.1rem -5rem hsl(var(--shadow-light) / 0.025);
		--shadow-side: 0rem 0rem 1rem -0.5rem hsl(var(--shadow-light) / 0.13),0rem 0rem 0rem 0rem hsl(var(--shadow-light) / 0.11),1rem 1rem 1.3rem -1.5rem hsl(var(--shadow-light) / 0.08),4rem 2.5rem 3.6rem -1.7rem hsl(var(--shadow-light) / 0.05),8.8rem 6.8rem 8.1rem -2rem hsl(var(--shadow-light) / 0.025);

		--shadow-low-nutri:
		5px -1px 10px -10px var(--low-nutri),
		4px -2px 12px -10px var(--low-nutri),
		3px -3px 14px -10px var(--low-nutri),
		2px -4px 16px -10px var(--low-nutri),
		1px 5px 18px -10px var(--low-nutri);

		--shadow-avg-nutri:
		5px -1px 10px -10px var(--avg-nutri),
		4px -2px 12px -10px var(--avg-nutri),
		3px -3px 14px -10px var(--avg-nutri),
		2px -4px 16px -10px var(--avg-nutri),
		1px 5px 18px -10px var(--avg-nutri);

		--shadow-high-nutri:
		5px -1px 10px -10px var(--high-nutri),
		4px -2px 12px -10px var(--high-nutri),
		3px -3px 14px -10px var(--high-nutri),
		2px -4px 16px -10px var(--high-nutri),
		1px 5px 18px -10px var(--high-nutri);

		--shadow-color-s:
		0.5px 0.2px 0.8px hsl(var(--shadowhighlight) / 0),
		2.8px 1.1px 4.5px hsl(var(--shadowhighlight) / 0.01),
		4.9px 1.9px 7.9px hsl(var(--shadowhighlight) / 0.02),
		6.9px 2.7px 11.1px hsl(var(--shadowhighlight) / 0.03),
		9px 3.6px 14.5px hsl(var(--shadowhighlight) / 0.04),
		11.6px 4.6px 18.7px hsl(var(--shadowhighlight) / 0.05),
		15px 5.9px 24.2px hsl(var(--shadowhighlight) / 0.06),
		19.3px 7.6px 31.1px hsl(var(--shadowhighlight) / 0.07),
		24.8px 9.8px 40px hsl(var(--shadowhighlight) / 0.08),
		31.8px 12.5px 51.3px hsl(var(--shadowhighlight) / 0.1);


		--shadow-high2: -20px 10px 80px -0px hsl(var(--shadowhighlight) / 0.5),
		-50px 0px 30px -50px hsl(var(--shadowhighlight) / 0.2),
		0px -10px 50px -0px hsl(var(--shadowhighlight) / 0.3),
		50px 0px 60px -55px hsl(var(--shadowhighlight) / 1),
		0px -40px 80px -0px hsl(var(--shadowhighlight) / 0.41),
		-10px 0px 10px -35px hsl(var(--shadowhighlight) / 1),
		0px 10px 12px -40px hsl(var(--shadowhighlight) / 1);

	}
	/*
	 * iOS Dark Theme
	 * -------------------------------------------
	 */

	.ios body.dark {
		--ion-background-color: #000000;
		--ion-background-color-rgb: 0, 0, 0;

		--ion-text-color: #ffffff;
		--ion-text-color-rgb: 255, 255, 255;

		--ion-color-step-50: #0d0d0d;
		--ion-color-step-100: #1a1a1a;
		--ion-color-step-150: #262626;
		--ion-color-step-200: #333333;
		--ion-color-step-250: #404040;
		--ion-color-step-300: #4d4d4d;
		--ion-color-step-350: #595959;
		--ion-color-step-400: #666666;
		--ion-color-step-450: #737373;
		--ion-color-step-500: #808080;
		--ion-color-step-550: #8c8c8c;
		--ion-color-step-600: #999999;
		--ion-color-step-650: #a6a6a6;
		--ion-color-step-700: #b3b3b3;
		--ion-color-step-750: #bfbfbf;
		--ion-color-step-800: #cccccc;
		--ion-color-step-850: #d9d9d9;
		--ion-color-step-900: #e6e6e6;
		--ion-color-step-950: #f2f2f2;

		--ion-item-background: #000000;

		--ion-card-background: #1c1c1d;
	}

	.ios ion-modal {
		--ion-background-color: var(--ion-color-step-100);
		--ion-toolbar-background: var(--ion-color-step-150);
		--ion-toolbar-border-color: var(--ion-color-step-250);
	}


	/*
	 * Material Design Dark Theme
	 * -------------------------------------------
	 */

	.md body.dark {
		--ion-background-color: #121212;
		--ion-background-color-rgb: 18, 18, 18;

		--ion-text-color: #ffffff;
		--ion-text-color-rgb: 255, 255, 255;

		--ion-border-color: #222222;

		--ion-color-step-50: #1e1e1e;
		--ion-color-step-100: #2a2a2a;
		--ion-color-step-150: #363636;
		--ion-color-step-200: #414141;
		--ion-color-step-250: #4d4d4d;
		--ion-color-step-300: #595959;
		--ion-color-step-350: #656565;
		--ion-color-step-400: #717171;
		--ion-color-step-450: #7d7d7d;
		--ion-color-step-500: #898989;
		--ion-color-step-550: #949494;
		--ion-color-step-600: #a0a0a0;
		--ion-color-step-650: #acacac;
		--ion-color-step-700: #b8b8b8;
		--ion-color-step-750: #c4c4c4;
		--ion-color-step-800: #d0d0d0;
		--ion-color-step-850: #dbdbdb;
		--ion-color-step-900: #e7e7e7;
		--ion-color-step-950: #f3f3f3;

		--ion-item-background: #1e1e1e;

		--ion-toolbar-background: #1f1f1f;

		--ion-tab-bar-background: #1f1f1f;

		--ion-card-background: #1e1e1e;
	}
}



/** Fonts **/
@font-face {
	font-family: 'Poppins';
	src: url("../assets/fonts/Poppins-Regular.ttf");
}

@font-face {
	font-family: 'poppinsv';
	src: url("../assets/fonts/PoppinsV.otf");
}

@font-face {
	font-family: 'Poppins-Bold';
	src: url("../assets/fonts/Poppins-Bold.ttf");
}

@font-face {
	font-family: 'simple';
	src: url("../assets/fonts/outfit.ttf");
}

@font-face {
	font-family: 'arabic';
	src: url("../assets/fonts/arabic.ttf");
}

@font-face {
	font-family: 'number2';
	src: url("../assets/fonts/Bungee-Regular.otf");
}

@font-face {
	font-family: 'comfort';
	src: url("../assets/fonts/Comfortaa-VariableFont_wght.ttf");
}

@font-face {
	font-family: 'onmain';
	src: url("../assets/fonts/onmain.ttf");
}

@font-face {
	font-family: 'onmaindark';
	src: url("../assets/fonts/onmaindark.ttf");
}

@font-face {
	font-family: 'outmain';
	src: url("../assets/fonts/outmain.ttf");
}

@font-face {
	font-family: 'classy';
	src: url("../assets/fonts/SofiaSans-Variable.ttf");
}

@font-face {
	font-family: 'clear';
	src: url("../assets/fonts/TitilliumWeb-Regular.ttf");
}

@font-face {
	font-family: 'number1';
	src: url("../assets/fonts/Jersey25-Regular.ttf");
}
@font-face {
	font-family: 'nice';
	src: url("../assets/fonts/noto.ttf");
}

:root {
	--ion-font-family: 'nice';
	font-family: auto;
}

:root[mode=md] {
	--ion-font-family: 'nice';
	font-family: auto;
}

:root[mode=ios] {
	--ion-font-family: 'nice';
	font-family: auto;
}

:root[dir="rtl"]{
	--ion-font-family: 'arabic';
	font-family: auto;

}