@import "https://unpkg.com/open-props@1.6.17/easings.min.css";

:root {
  --primary-light: #8abdff;
  --primary: #6d5dfc;
  --primary-dark: #5b0eeb;
  
  --white: #FFFFFF;
  --greyLight-1: #E4EBF5;
  --greyLight-2: #c8d0e7;
  --dark: #50505070;
  --darker: #00000053;
	--gr: 0deg;


  --outset: -0.1rem 0.1rem 0.25rem var(--dark), 
-0.125rem 0.125rem 0.3rem var(--darker);
--inset: inset -0.1rem 0.1rem 0.25rem var(--gdark), 
inset -0.125rem 0.125rem 0.3rem var(--darker);


--outset-b: -0.2rem 0.2rem 0.4rem var(--dark), 
-0.25rem 0.25rem 0.5rem var(--darker);
--inset-b: inset -0.2rem 0.2rem 0.4rem var(--gdark), 
inset -0.25rem 0.25rem 0.5rem var(--darker);



}

// ion-card{
//   margin: 0px auto;
//   padding: 0;
//   }

  
// Macros Colors
.calfull {
background: var(--kcal);
}

.carbfull {
background: var(--carb);
}

.profull {
background: var(--prot);
}

.fatfull {
background: var(--fat);

}

.caloutline {
border-color: var(--kcal);
}

.carboutline {
border-color: var(--carb);
}

.prooutline {
border-color: var(--prot);
}

.fatoutline {
border-color: var(--fat);
}

.calfont {
  color: var(--kcal);
  }
  
  .carbfont {
  color: var(--carb);
  }
  
  .protfont {
  color: var(--prot);
  }
  
  .fatfont {
  color: var(--fat);
  }
  


@keyframes rotgrad {
	0% {
	  --gr: 0deg;
	}
	100% {
	  --gr: 45deg;
	}
  }
  .rotater{
    animation: rotgrad 5s infinite linear;
                   background: linear-gradient(var(--gr),var(--diffuse-light), transparent,var(--diffuse-light)) ;
  }
@keyframes userborder{
    0%,
    100% {
      border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%;
    }
    14% {
      border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%;
    }
    28% {
      border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%;
    }
    42% {
      border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%;
    }
    56% {
      border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%;
    }
    70% {
      border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%;
    }
    84% {
      border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%;
    }
  }


.bordermove{
  animation-duration: 10s;
  animation-name: userborder;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
.modalicon{
height: 10vw;
width: 10vw;
stroke: var(--ion-color-danger);
fill: none;
stroke-width: 1px;
}
   
.pageicon{
  width: 50vw;
  height: 50vw;
  stroke: none;
  fill: var(--ion-color-tertiary);
  stroke-width: 1px;
  filter: drop-shadow(-0.05rem 0.07rem 0.03rem rgba(0, 0, 0, 0.45));
} 
.pageicon-s{
  width: 50vw;
  height: 50vw;
  fill: none;
  stroke: var(--ion-color-tertiary);
  stroke-width: 1px;
  filter: drop-shadow(-0.05rem 0.07rem 0.03rem rgba(0, 0, 0, 0.45));
  // display: flex;
} 

.shadowico{
  filter: drop-shadow(-0.05rem 0.07rem 0.03rem rgba(0, 0, 0, 0.45));
}
.tabmainico{
  height: 10vw;
  width: 10vw;
  stroke: none;
  stroke-width: 1px;
  fill: var(--ion-color-primary);
}
.accounticon{
  height: 10vw;
  width: 10vw;
  stroke: none;
  stroke-width: 0px;
  margin: 0vw;
  fill: var(--ion-color-primary);
}

.accounticon-outline{
  height: 10vw;
  width: 10vw;
  fill: none;
  stroke-width: 1px;
  margin: 0 0 0 5vw;
  stroke: var(--ion-color-primary);
  
}
.smallicon-outline{

  fill: none;
  stroke-width: 1px;
  stroke: var(--ion-color-primary);
  height: 7vw;
  width: 7vw;
}

.redicon{
stroke:var(--ion-color-danger);
fill: #fff;
stroke-width: 1px;
height: 7vw;
  width: 7vw;
}


.redicon-filled{
  fill: var(--ion-color-danger);
  stroke: #fff;
  stroke-width: 1px;
  height: 7vw;
  width: 7vw;
  }
.inputicon{
  height: 10vw;
  width: 10vw;
  fill: var(--ion-color-primary);
  stroke-width: 0px;
  stroke: none;
  margin: 0 0vw;
  
}

.inputicon-b{
  height: 25vw;
  width: 25vw;
  fill: var(--ion-color-primary);
  stroke-width: 0px;
  stroke: none;
  margin: 0 0vw;
  
}
.inputicon-s{
  height: 24px;
  width: 24px;
  stroke: var(--ion-color-primary);
  stroke-width: 1px;
  fill: none;
  
}
.inputicon-f{
  height: 24px;
  width: 24px;
  fill: var(--ion-color-primary);
  stroke-width: 0.5px;
  stroke: none;
  
}
.addressico{
  height: 10vw;
  width: 10vw;
  fill: var(--ion-color-primary);
  stroke-width: 0px;
  stroke: none;
  margin: 0 0vw;
  
}
.subicon-s{
  height: 8vw;
  width: 8vw;
  fill: none;
  stroke: var(--ion-color-primary);
  stroke-width: 1px;
}
.subicon{
  height: 4vw;
  width: 4vw;
  fill: none;
  // stroke: var(--ion-color-primary);
  stroke-width: 2px;
  border-radius: 10px;
  box-shadow: var(--shadow-h);
  padding: 1vw;
  background: var(--ion-color-danger);
}
.addressico-s{
  height: 6vw;
  width: 10vw;
  stroke: var(--ion-color-primary);
  stroke-width: 2px;
  fill: none;
  box-shadow: var(--shadow-mid);
  border-radius: 10px;
  padding: 1vw;
  
}
.notifimg{
  height: 100%;
  width: 100%;
  stroke-width: 2px;
}
.calendaricon{
  //height: 35px;
  width: 35px;
  //stroke: var(--ion-color-primary);
  stroke-width: 1px;
 // fill: none;
}
.legendicon{
  // height: 35px;
  width: 32px;
  height: 32px;
  stroke: var(--ion-color-primary);
  stroke-width: 1.5px;
 fill: none;
}

.displayicon{
  height: 35px;
  width: 35px;
  stroke: #000;
  stroke-width: 1px;
  fill: none;
  // filter: drop-shadow(-3.5px 1.5px 1px rgba(0, 0, 0, 0.45));
  ;
}

.tabicon{
  height: 35px;
  width: 35px;
  stroke-width: 1px;
  stroke: var(--ion-color-secondary);
  color: transparent;
//  filter: drop-shadow(-3.5px 1.5px 1px rgba(0, 0, 0, 0.45));
  
}

.tabicon-fill{
  height: 35px;
  width: 35px;
  stroke-width: 1px;
  stroke: none;
  color: var(--ion-color-secondary);
//  filter: drop-shadow(-3.5px 1.5px 1px rgba(0, 0, 0, 0.45));
  
}

.container3d{
  border: 2.5px solid var(--ion-color-light);
  //border-radius: 50px;
  background: transparent;
  box-shadow: inset 0rem 0rem 0rem rgba(0, 0, 0, 0.45), -0.1rem 0.1rem 0.3rem rgba(0, 0, 0, 0.45);
}

.openclear{

overflow: visible;
contain: none;
background:transparent;
--background:transparent;
//box-shadow: none;
}

.d3font{
 //   font-size: 12em;
    font-weight: 200;
    color: #f5f5f5;
   text-shadow: 0.1px 0.1px 0.1px #919191,
        0.1px  0.2px 0.1px #919191,
        0.1px  0.3px 0.1px #919191,
        0.1px  0.4px 0.1px #919191,
        0.1px  0.5px 0.1px #919191,
        0.1px  0.6px 0.1px #919191,
        0.1px  0.7px 0.1px #919191,
        0.1px  0.8px 0.1px #919191,
        0.1px  0.9px 0.1px #919191,
        0.1px  1px 0.1px #919191,
        0.1px 1.8px 0.6px rgba(16,16,16,0.4),
        0.1px 2.2px 1px rgba(16,16,16,0.2),
        0.1px 2.5px 3.5px rgba(16,16,16,0.2),
        0.1px 3px 6px rgba(16,16,16,0.4);
}
.nomargin{
    margin: 0;
}
.nopadding{
    padding: 0;
}
    .step-a {
      height: 48px;
      width: 48px;
      fill: var(--s3);
      stroke: var(--ion-color-primary);
      stroke-width: 1.5px;
      fill-opacity: 0;
    }

    .step-2 {
      height: 42px;
      width: 42px;
      fill:var(--ion-color-primary);
      stroke: var(--s3);
      stroke-width: 1.5px;
      fill-opacity: 0;
    }


    .step {
      height: 42px;
      width: 42px;
      fill: none;
      stroke: #000;
      stroke-width: 1px;
    }
    
    .steps{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    
    .buttonsimple{
      --border-radius: 32px;
      min-height: 24px;
      font-size: 16px;
      --background: var(--ion-color-primary);
      --box-shadow: -0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.45), inset -0.25rem 0.25rem 0.5rem rgba(0, 0, 0, 0.45);
    }


    svg.fillmeal {
    fill: var(--ion-color-primary);
    }
 
    
.doubleicon{
  stroke: var(--ion-color-primary);
  fill: var(--p2);
  stroke-width: 1.5px;

}

.fullicon{
  stroke: none;
  fill: var(--ion-color-primary);
  //stroke-width: 1.5px;

}

.fullicon-s{
  fill: none;
  stroke: var(--ion-color-primary);
  stroke-width: 1.5px;

}

.downicon{
  stroke: var(--ion-color-secondary);
  fill: none;
  stroke-width: 1.5px;
}



.banner3d {
  position: relative;
  background: linear-gradient(46deg, var(--t2) 0%, var(--p3) 30%);
  border-radius: 20px;
}
 
.banner3d::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 5%;
  right: 5%;
  bottom: 0;
  border-radius: 10px;
  background: color-mix(in srgb, var(--ion-color-primary) 50%, black 30%);
  transform: translate(0, -15%) rotate(-4deg);
  transform-origin: center center;
  box-shadow: 0 0 10px 7px color-mix(in srgb, var(--ion-color-primary) 50%, black 30%);
  z-index: -1;
}

.dinar{
  
    stroke: var(--ion-color-secondary);
    stroke-width: 2px;
    fill: none;
    width: 7vw !important;
    height: 7vw !important;
    
}
.wallet{
  
  stroke: var(--ion-color-secondary);
  stroke-width: 1.5px;
  fill: none;
  width: 14vw !important;
  height: 14vw !important;
  
}

.mainlogoicon{
  // stroke: var(--ion-color-secondary);
  color: var(--p3);
  stroke-width: 0px;
  fill: var(--p3);
  width: 60vw !important;
  height: 60vw !important;
}

.backmountainico-prime{
  position: absolute;
  // fill: var(--p3);
  stroke-width: 0px;
  stroke: none;
 width: 800vw !important;
    height: 240vw !important;
  bottom: 0;
  left: 0;
  // color: var(--p3);
  backface-visibility: hidden;
}

.backmountainico-sec{
  position: absolute;
  fill: var(--s3);
  stroke-width: 0px;
  stroke: none;
 width: 900vw !important;
    height: 270vw !important;
  bottom: 0;
  left: 0;
  color: var(--s3);
}

.backmountainico-tri{
  position: absolute;
  fill: var(--ion-color-tertiary);
  stroke-width: 0px;
  stroke: none;
  width: 750vw !important;
    height: 125vw !important;
  bottom: 0;
  left: 0;
  color: var(--ion-color-tertiary);
}

.backmountainico-fade{
  position: absolute;
  fill: var(--p2);
  stroke-width: 0px;
  stroke: none;
 width: 750vw !important;
    height: 125vw !important;
  bottom: 0;
  left: 0;
  color: var(--p2);
}

.onbb{
  color: var(--p3);
  position: relative;
  stroke-width: 0px;
  stroke: var(--ion-color-dark);
  width: 25% !important;
  height: 15vw !important;
  margin: 1vw;
  bottom: 0;
  left: 0;
  color: var(--p3);
  transform: translate3d(0, 0, 75px);
  transform-style: preserve-3d;
  backface-visibility: hidden;
}
.iconmode{
  stroke: var(--ion-color-dark);
  fill: none;
  height: 8vw;
  width: 8vw;
}
.formimg{
  fill: var(--p3);
  stroke-width: 0px;
  stroke: none;
  width: 100% !important;
  height: 100% !important;
pointer-events: none;
  color: var(--p3);
  backface-visibility: hidden;
  //filter: drop-shadow(-1px 1px 3px var(--pp));
}

.sbb{
  position: absolute;
  fill: var(--p3);
  stroke-width: 0px;
  stroke: none;
 width: 80vw !important;
    height: 60vw !important;
  bottom: 0;
  left: 10%;
  color: var(--p3);
}
.menuico{
  
  stroke: var(--ion-color-secondary);
  stroke-width: 1.5px;
  fill: none;
  height: 18vw !important;
  width: 18vw !important; 
  
}
.accountico{
  
  stroke: var(--ion-color-secondary);
  stroke-width: 1.5px;
  fill: none;
  height: 8vw !important;
  width: 8vw !important;  
  
}
.settingsico{
  fill: var(--ion-color-secondary);
  stroke-width: 1px;
  stroke: var(--ion-color-secondary);
  height: 6vw !important;
  width: 6vw !important;
}
.onboardicon{
  
  fill: var(--p2);
  stroke-width: 1px;
  stroke: none;
  height: 10vw !important;
  width: 10vw !important;  
  
}

.onboardicon-s{
  fill: var(--p3);
  stroke-width: 1px;
  stroke: var(--ion-color-dark);
  height: 10vw !important;
  width: 10vw !important;
  
}


.gifcolor {
transform: scaleX(-1);
}

.iconlook{
  will-change: contents;
  transition: all 1s ease-in-out;
  height: 32px;
  width: 32px;
  position: relative;
  pointer-events: none;
  transform-origin: center;
  animation: iconshow 10s infinite;
  filter: drop-shadow(-0.05rem 0.07rem 0.03rem rgba(0, 0, 0, 0.45));

}

@keyframes iconshow {
  0%, 100% {
    filter: drop-shadow(-0.05rem 0.07rem 0.03rem rgba(0, 0, 0, 0.45));
   // scale: 1;
    stroke-width: 0px;
    pointer-events: auto;
  }
  50% {
   // filter: drop-shadow(-0.0rem 0.0rem 0.0rem rgba(0, 0, 0, 0.45));
    scale: 0.7;
    pointer-events: auto;
    stroke-width: 2px;
  }
}

.iconmove{
  will-change: contents;
  transition: all 1s ease-in-out;
  height: 32px;
  width: 32px;
  position: relative;
  pointer-events: none;
  stroke-width: 2px;
  animation: iconanim 10s infinite;

}


@keyframes iconanim {
  0%, 100% {
    transform: rotate3d(1, 1, 1, 0deg);
    pointer-events: auto;
  }
  25% {
    pointer-events: auto;
    transform: rotate3d(1, 1, 1, 0deg);
  }
  50% {
    pointer-events: auto;
    transform: rotate3d(1, 1, 1, 360deg);
  }
  75% {
    pointer-events: auto;
    transform: rotate3d(1, 1, 1, 0deg);
  }
}



.container3dinner{
  box-shadow: inset -0.1rem 0.1rem 0.3rem rgba(0, 0, 0, 0.403);
}


.inputsmallicon{
  height: 10px;
  width: 10px;
  stroke:var(--danger-false);
  stroke-width: 2px;
  fill: none;
  animation: iconshow 10s infinite;
  pointer-events: auto;
}
.pointerm{
  pointer-events: none;
}

.d3fontanim{
  text-shadow: -0.02rem 0.02rem 0.1rem var(--ion-color-primary), -0.04rem 0.04rem 0.1rem var(--ion-color-secondary), -0.08rem 0.08rem 0.1rem var(--ion-color-tertiary), -0.16rem 0.16rem 0.1rem var(--ion-color-dark);
  animation: d3fonter 3s infinite;
  animation: backgrounder 5s ease-in-out infinite;
  // background:linear-gradient(30deg, var(--ion-color-tertiary) 0%, var(--ion-color-secondary) 50%,var(--ion-color-primary) 100% ) !important;
  background-size: 400%;
}

.backgroundanim{
 // text-shadow: -0.02rem 0.02rem 0.1rem var(--ion-color-primary), -0.04rem 0.04rem 0.1rem var(--ion-color-secondary), -0.08rem 0.08rem 0.1rem var(--ion-color-tertiary), -0.16rem 0.16rem 0.1rem var(--ion-color-dark);
 // animation: d3fonter 3s infinite;
  animation: backgrounder 25s ease-in-out infinite;
  background:linear-gradient(30deg, var(--ion-color-tertiary) 0%, var(--ion-color-secondary) 50%,var(--ion-color-primary) 100% );
  background-size: 800%;
}

@keyframes d3fonter {
  0%, 100% {
    text-shadow: -0.02rem 0.02rem 0.1rem var(--ion-color-primary), -0.04rem 0.04rem 0.1rem var(--ion-color-secondary), -0.08rem 0.08rem 0.1rem var(--ion-color-tertiary), -0.16rem 0.16rem 0.1rem var(--ion-color-dark);
    // scale: 1;
  }
  50% {
    text-shadow: -0.02rem 0.02rem 0.1rem #919191, -0.04rem 0.04rem 0.1rem #919191, -0.08rem 0.08rem 0.1rem #919191, -0.16rem 0.16rem 0.1rem #919191;
    // scale: 0.7;
  }
}
@keyframes backgrounder {
  0%,100% {
  background-position: 0% 0%;
  }
  20% {
  background-position: 25% 75%;
  }
  40% {
  background-position: 50% 50%;
  }
  60% {
  background-position: 75% 25%;
  }
  80% {
  background-position: 50% 50%;
  }
  }
@keyframes borderer {
  0% {
      box-shadow:  -0.1rem 0.1rem 0.3rem linear-gradient(30deg, var(--ion-color-tertiary) 0%, var(--ion-color-secondary) 50%,var(--ion-color-primary) 100% );
  }
  50% {
    box-shadow:  -0.1rem 0.1rem 0.3rem linear-gradient(80deg, var(--ion-color-secondary) 0%, var(--ion-color-primary) 50%,var(--ion-color-tertiary) 100% );
  }
  100% {
    box-shadow:  -0.1rem 0.1rem 0.3rem linear-gradient(50deg, var(--ion-color-primary) 0%, var(--ion-color-tertiary) 50%,var(--ion-color-secondary) 100% );
  }
}

.borderanim{
  
  animation: borderer 5s ease-in-out infinite;
  box-shadow :linear-gradient(30deg, var(--ion-color-tertiary) 0%, var(--ion-color-secondary) 50%,var(--ion-color-primary) 100% );
  // background-size: 400%;
}

.container3danimlarge {
  border: 4px solid var(--ion-color-light);
  border-radius: 30px;
  box-shadow: inset -0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.45), -0.2rem 0.2rem 0.5rem rgba(0, 0, 0, 0.45);
  animation: container3dabig 10s infinite;
}

@keyframes container3dabig {
  0%, 100% {
    box-shadow: inset -0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.45), -0.2rem 0.2rem 0.5rem rgba(0, 0, 0, 0.45);
  }
  50% {
    box-shadow: inset 0rem 0rem 0rem rgba(0, 0, 0, 0.45), -0.1rem 0.1rem 0.3rem rgba(0, 0, 0, 0.45);
  }
}


.caloicon {
  background: linear-gradient(0deg, #FFD966 0%, #FFD966 9%, #fff0 10%, #fff0 100%);
  border: 0px solid #FFD966;
  color: #FFD966;
 // min-width: 10vw;
}

.proticon {
  background: linear-gradient(180deg, #5B9BD5 0%, #5B9BD5 9%, #fff0 10%, #fff0 100%);
  border: 0px solid #5B9BD5;
  color: #5B9BD5;
  //width: 10vw;
}

.carbsicon {
  background: linear-gradient(180deg, #A9D08E 0%, #A9D08E 9%, #fff0 10%, #fff0 100%);
  border: 0px solid #A9D08E;
  color: #A9D08E;
  //width: 10vw;
}

.fatsicon {
  background: linear-gradient(180deg, #E6B8B7 0%, #E6B8B7 9%, #fff0 10%, #fff0 100%);
  border: 0px solid #E6B8B7;
  color: #e688b7;
  //width: 15vw;
  //display: flex;
 // align-items: center;
}

.clearback{
  background: #ffffff95;
  backdrop-filter: blur(10px);
}

.loadanim {
//  background-image: url("../assets/loadanim1.svg") !important;
  position: absolute;
  bottom: 30%;
  //left: 50%;
  opacity: 1;
  width: 100vw;
  min-height: 30vw;
  background-size: contain;
  background-repeat: no-repeat !important;
  background-position: center;
  z-index: 999999;
  stroke: red;
  stroke-width: 5px;
}

.loadanimg {
  // background-image: url("../assets/loadanim.svg") !important;
   position: absolute;
   bottom: 20%;
   //left: 50%;
   opacity: 1;
   width: 100vw;
   min-height: 50px;
   background-size: contain;
   background-repeat: no-repeat !important;
   background-position: center;
   z-index: 999999;
   stroke: var(--ion-color-primary);
   stroke-width: 5px;
 }

 .cbutton {
  position: relative;
  overflow: hidden;
  border: 1px solid var(--s2); /* Equivalent to bg-neutral-800 */
  color: #F9FAFB;
  // background-color: var(--ion-color-primary); /* Equivalent to text-gray-50 */
  transform-origin: center;
  font-size: 15px;
  font-weight: 700;
  text-transform: capitalize;
	
}

.cbutton::before,
.cbutton::after {
  content: '';
  position: absolute;
  border-radius: 50%;
  z-index: 10;
  filter: blur(1.5rem); /* Approximation of blur-lg */
  animation: pulse 5s infinite ease-in-out;
}

@keyframes pulse {
  0%, 100% {
      right: 0.5rem;
      bottom: 0.5rem;
      transform: scale(1);
      box-shadow: 0px 0px 0px 0px var(--s2);
  }
  50% {
      right: 3rem;
      bottom: -1rem;
      transform: scale(1.5);
      box-shadow: 20px 20px 20px 30px var(--s2);
  }
}

.poplow {
  box-shadow: var(--shadow-elevation-low);
  transition: all 0.5s ease-in-out;
}

.popmedium {
  box-shadow: var(--shadow-elevation-medium);
  transition: all 0.5s ease-in-out;
}

.pophigh {
  box-shadow: var(--shadow-elevation-high);
  transition: all 0.5s ease-in-out;
}


.popmid {
  box-shadow: var(--shadow-mid);
  transition: all 0.5s ease-in-out;
}

.popdiffuse {
  box-shadow: var(--shadow-diffuse);
  transition: all 0.5s ease-in-out;
}

.popl {
  box-shadow: var(--shadow-l);
  transition: all 0.5s ease-in-out;
}

.popm {
  box-shadow: var(--shadow-m);
  transition: all 0.5s ease-in-out;
}

.poph {
  box-shadow: var(--shadow-h);
  transition: all 0.5s ease-in-out;
}

.popc {
  box-shadow: var(--shadow-c);
  transition: all 0.5s ease-in-out;
}

.popultra {
  box-shadow: var(--shadow-h2);
  transition: all 0.5s ease-in-out;
}


.jumpdiv{
  animation: jumdiv 5s infinite;

}

.jumpdiv-l{
  animation: jumdiv-l 2s infinite;
}

.jumpdiv-m{
  animation: jumdiv-m 3s infinite;
}

.jumpdiv-h{
  animation: jumdiv-h 5s infinite;
}


@keyframes jumdiv-l {
  0%, 100% {
    box-shadow: 0.2px 0.4px 0.4px hsla(0, 0%, 0%, 1);
    scale: 0.90;
  }
  50% {
    box-shadow: 5px 10px 10px hsla(0, 0%, 0%, 0.25);
    scale: 0.95;
  }
}

@keyframes jumdiv-m {
  0%, 100% {
    box-shadow: 0.1px 0.4px 0.4px hsl(0deg 0% 0% / 0.50);
    scale: 0.9;
  }
  50% {
    box-shadow: 2px 8px 8px hsla(0, 0%, 0%, 0.25);
    scale: 0.95;
  }
}

@keyframes jumdiv-h {
  0%, 100% {
    box-shadow: 1px 2px 2px hsl(0deg 0% 0% / 0.50);
    scale: 1;
  }
  50% {
    box-shadow: 12.5px 25px 25px hsla(0, 0%, 0%, 0.25);
    scale: 1.05;
  }
}

@keyframes jumdiv {
  0%, 100% {
    box-shadow: 1px 2px 2px hsl(0deg 0% 0% / 0.25), 1.5px 3px 3px hsl(0deg 0% 0% / 0.25),2px 4px 4px hsla(0, 0%, 0%, 0.25),2.5px 5px 5px hsl(0deg 0% 0% / 0.25);
    // scale: 0.8;
    transform: scale(0.8) translateY(5%);
  }
  50% {
    box-shadow: 4.5px 9px 9px hsla(0, 0%, 0%, 0.1),4px 8px 8px hsla(0, 0%, 0%, 0.1),3.5px 7px 7px hsla(0, 0%, 0%, 0.1),3px 6px 6px hsla(0, 0%, 0%, 0.1);
    // scale: 0.85;
    transform: scale(0.85) translateY(-5%);
  }
}

@keyframes caljump {
  0%, 100% {
    box-shadow: var(--shadow-md);
    scale: 0.9;
    // transform: scale(0.95) translateY(0);
  }
  50% {
      box-shadow: var(--shadow-m);
    scale: 0.95;
    // transform: scale(1) translateY(-0);
  }
}
.macroscal{
  display: flex;
  gap: 1vw;
  padding: 1vw;
  margin: 0;
  box-shadow: var(--shadow-md);
  border-radius: 10px;
  background: linear-gradient(var(--r1), transparent 0%, var(--diffuse-light-selected) 90%) !important;

.warning{
display: flex;
padding: 1vw;
flex-direction: row-reverse;
align-items: center;
gap: 1vw;
margin: 0;
p{
margin: 0;
font-size: 14px;
font-weight: 200;
}
small{
margin: 0;
font-size: 14px;
color: var(--ion-color-dark);
}
}
}

// <ion-card class="macroscal openclear ">
							
// <ion-card class="warning caloicon ">
//   <ion-icon class=" calicon" src="assets/svg/pen.svg"></ion-icon>
//   <p class="black">{{calories}}</p>
//   <small class="black">{{"cal" | translate}}</small>
// </ion-card>
// <ion-card class="warning proticon ">
//   <ion-icon class=" proicon" src="assets/svg/sqr.svg"></ion-icon>
//   <p class="black">{{protein}}</p>
//   <small class="black">{{"p" | translate}}</small>
// </ion-card>
// <ion-card class="warning carbsicon ">
//   <ion-icon class=" carbicon" src="assets/svg/cir.svg"></ion-icon>
//   <p class="black">{{carbs}}</p>
//   <small class="black">{{"c" | translate}}</small>
// </ion-card>
// <ion-card class="warning fatsicon ">
//   <ion-icon class=" faticon" src="assets/svg/tri.svg"></ion-icon>
//   <p class="black">{{fats}}</p>
//   <small class="black">{{"f" | translate}}</small>
// </ion-card>

// </ion-card>	
.typing-indicator {
  width: 120px;
  height: 60px;
  position: absolute;
  z-index: 4;
  bottom: 0vw;
  left: 50%;
  transform: translate(-50%, 0);
}

.typing-circle {
  width: 16px;
  height: 16px;
  position: absolute;
  border-radius: 50%;
  background-color: var(--ion-color-secondary);
  left: 15%;
  transform-origin: 50%;
  animation: typing-circle7124 0.5s alternate infinite ease;
}

@keyframes typing-circle7124 {
  0% {
    top: 40px;
    height: 10px;
    border-radius: 50px 50px 25px 25px;
    transform: scaleX(1.7);
  }

  40% {
    height: 16px;
    border-radius: 50%;
    transform: scaleX(1);
  }

  100% {
    top: 0%;
  }
}

.typing-circle:nth-child(2) {
  left: 45%;
  animation-delay: 0.2s;
}

.typing-circle:nth-child(3) {
  left: auto;
  right: 15%;
  animation-delay: 0.3s;
}

.typing-shadow {
  width: 10px;
  height: 8px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 60px;
  transform-origin: 50%;
  z-index: 3;
  left: 15%;
  filter: blur(1px);
  animation: typing-shadow046 0.5s alternate infinite ease;
}

@keyframes typing-shadow046 {
  0% {
    transform: scaleX(1.5);
  }

  40% {
    transform: scaleX(1);
    opacity: 0.7;
  }

  100% {
    transform: scaleX(0.2);
    opacity: 0.4;
  }
}

.typing-shadow:nth-child(4) {
  left: 45%;
  animation-delay: 0.2s;
}

.typing-shadow:nth-child(5) {
  left: auto;
  right: 15%;
  animation-delay: 0.3s;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.panWrapper {
  width: 200px;
  height: fit-content;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.pan {
  display: flex;
  width: 100%;
  height: fit-content;
  animation: cooking 1.7s infinite;
}

.food {
  position: absolute;
  width: 40%;
  height: 6px;
  background: linear-gradient(to bottom, var(--ion-color-primary), var(--ion-color-tertiary));
  border-radius: 50%;
  animation: flip 1.7s infinite;
  z-index: 2;
}
@keyframes flip {
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  50% {
    transform: translateY(-100px) rotate(180deg);
  }
  100% {
    transform: translateY(0px) rotate(360deg);
  }
}
.panBase {
  z-index: 3;
  width: 50%;
  height: 22px;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  background: linear-gradient(to top, var(--s3), var(--s2));
}
.panHandle {
  width: 40%;
  background: linear-gradient(to bottom, var(--ion-dark), var(--ion-color-dark));
  height: 10px;
  border-radius: 10px;
}
.panShadow {
  width: 70px;
  height: 8px;
  background-color: rgba(0, 0, 0, 0.21);
  
  border-radius: 10px;
  animation: shadow 1.7s infinite;
  filter: blur(15px);
}
@keyframes shadow {
  0% {
    transform: scaleX(0.7);
  }
  50% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0.7);
  }
}


// <div class="loader"></div>

// .wrapper {
//   width: 200px;
//   height: 60px;
//   position: relative;
//   z-index: 1;
// }

// .circle {
//   width: 20px;
//   height: 20px;
//   position: absolute;
//   border-radius: 50%;
//   background-color: #fff;
//   left: 15%;
//   transform-origin: 50%;
//   animation: circle7124 .5s alternate infinite ease;
// }

// @keyframes circle7124 {
//   0% {
//     top: 60px;
//     height: 5px;
//     border-radius: 50px 50px 25px 25px;
//     transform: scaleX(1.7);
//   }

//   40% {
//     height: 20px;
//     border-radius: 50%;
//     transform: scaleX(1);
//   }

//   100% {
//     top: 0%;
//   }
// }

// .circle:nth-child(2) {
//   left: 45%;
//   animation-delay: .2s;
// }

// .circle:nth-child(3) {
//   left: auto;
//   right: 15%;
//   animation-delay: .3s;
// }

// .shadow {
//   width: 20px;
//   height: 4px;
//   border-radius: 50%;
//   background-color: rgba(0,0,0,0.9);
//   position: absolute;
//   top: 62px;
//   transform-origin: 50%;
//   z-index: -1;
//   left: 15%;
//   filter: blur(1px);
//   animation: shadow046 .5s alternate infinite ease;
// }

// @keyframes shadow046 {
//   0% {
//     transform: scaleX(1.5);
//   }

//   40% {
//     transform: scaleX(1);
//     opacity: .7;
//   }

//   100% {
//     transform: scaleX(.2);
//     opacity: .4;
//   }
// }

// .shadow:nth-child(4) {
//   left: 45%;
//   animation-delay: .2s
// }

// .shadow:nth-child(5) {
//   left: auto;
//   right: 15%;
//   animation-delay: .3s;
// }

// .loader {
//   position: relative;
//   margin: auto;
//   box-sizing: border-box;
//   background-clip: padding-box;
//   width: 200px;
//   height: 200px;
//   border-radius: 100px;
//   border: 4px solid rgba(255, 255, 255, 0.1);
//   -webkit-mask: linear-gradient(rgba(0, 0, 0, 0.1), #000000 90%);
//   transform-origin: 50% 60%;
//   transform: translateY(60vh) perspective(200px) rotateX(66deg);
//   animation: spinner-wiggle 1.2s infinite;
// }

// .loader:before,
// .loader:after {
//   content: "";
//   position: absolute;
//   margin: -4px;
//   box-sizing: inherit;
//   width: inherit;
//   height: inherit;
//   border-radius: inherit;
//   opacity: .05;
//   border: inherit;
//   border-color: transparent;
//   animation: spinner-spin 1.2s cubic-bezier(0.6, 0.2, 0, 0.8) infinite, spinner-fade 1.2s linear infinite;
// }

// .loader:before {
//   border-top-color: var(--ion-color-primary);
// }

// .loader:after {
//   border-top-color: var(--ion-color-secondary);
//   animation-delay: 0.3s;
// }

// @keyframes spinner-spin {
//   100% {
//     transform: rotate(360deg);
//   }
// }

// @keyframes spinner-fade {
//   20% {
//     opacity: .1;
//   }

//   40% {
//     opacity: 1;
//   }

//   60% {
//     opacity: .1;
//   }
// }

.merakbranding{
  position: relative;
  bottom: 0;
  width: 100vw;
  left: 0;
  z-index: 999999;
  font-size: 20px;
  color: var(--ion-color-dark);
  height: 15vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
}

.container {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0%;
  left: 0%;
  transform: translate(0%, 40%);
  z-index: 999;
  margin: auto;
  overflow: visible;
  contain: none;
  transform-origin: center;
  filter: url(#goo);
  // scale: 0.5;
  animation: rotate-move 5s ease-in-out infinite;
  }
  
  .dot {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  // background-color: #0000;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  transform-origin: center;
  }
//   .dot-1 { left: 0; }
// .dot-2 { left: 60px; }
// .dot-3 { left: 120px; }

  .dot-3 {
  // background-color: var(--kcal);
  z-index: 3;
  // animation: dot-a-move 2s ease infinite, index 3s ease infinite;
  animation: index 4s ease-in-out forwards infinite, dot-a-move 3s ease-in-out forwards infinite,;
  }
  
  .dot-2 {
  // background-color: var(--ion-color-secondary);
  z-index: 2;
  // animation: dot4b-move 3s ease-in-out forwards infinite, index 3s -4s ease-in-out forwards infinite;
  animation: index 4s -4s ease-in-out forwards infinite, dot-b-move 3s ease-in-out forwards infinite;
  }
  
  .dot-1 {
  // background-color: var(--carb);
  z-index: 1;
  // animation: dot4c-move 3s ease-in-out forwards infinite, index 3s -2s ease-in-out forwards infinite;
  animation: index 4s -2s ease-in-out forwards infinite , dot-c-move 3s ease-in-out forwards infinite;
  
  }
  .dot-4 {
    // background-color: var(--p2);
    // scale: 1.3;
    // filter: blur(10px);
    z-index: 1;
    // animation: dot4c-move 3s ease-in-out forwards infinite, index 3s -2s ease-in-out forwards infinite;
    animation: index 4s -6s ease-in-out forwards infinite , dot-d-move 3s ease-in-out forwards infinite;
    
    }
    .dot-5 {
      background-color: var(--bg-light);
      scale: 5;
      // filter: blur(0px);
      z-index: 0;
      // animation: dot4c-move 3s ease-in-out forwards infinite, index 3s -2s ease-in-out forwards infinite;
      // animation: ind4x 5s -2s ease-in-out forwards infinite , dot-c-move 3s ease-in-out forwards infinite;
      
      }
  
  @keyframes dot-c-move {
0% {
transform: translate(20px, 20px) scale(1);
}
25% {
transform: translate(-10px, 10px) scale(0.5);
}
50% {
transform:  translate(-20px, 0px) scale(0.75);
}
75% {
transform:  translate(10px, -10px) scale(1.25);
}
100% {
transform:  translate(20px, 20px) scale(1);
}
}
@keyframes dot-a-move {
  0% {
    transform: translate(-20px, 10px) scale(1);
    }
    25% {
    transform: translate(0px, 10px) scale(0.5);
    }
    50% {
    transform:  translate(0px, 20px) scale(1.25);
    }
    75% {
    transform:  translate(10px, -20px) scale(0.75);
    }
    100% {
    transform:  translate(-20px, 10px) scale(1);
    }
  }
  @keyframes dot-b-move {
    0% {
      transform: translate(10px, -10px) scale(1);
      }
      25% {
      transform: translate(20px, -10px) scale(0.5);
      }
      50% {
      transform:  translate(0px, 0px) scale(1.15);
      }
      75% {
      transform:  translate(20px, -20px) scale(0.75);
      }
      100% {
      transform:  translate(10px, -10px) scale(1);
      }
}
@keyframes dot-d-move {
  0% {
    transform: translate(0px, 0px) scale(1);
    }
    25% {
    transform: translate(20px, 20px) scale(1.25);
    }
    50% {
    transform:  translate(0px, 10px) scale(0.75);
    }
    75% {
    transform:  translate(-20px, -20px) scale(0.5);
    }
    100% {
    transform:  translate(0px, 0px) scale(1);
    }
          }
    


  // @keyframes dot-3-move {
  // 20% {
  //   transform: scale(1);
  // }
  // 45% {
  //   transform: translateY(-18px) scale(0.45);
  // }
  // 60% {
  //   transform: translateY(-90px) scale(0.45);
  // }
  // 80% {
  //   transform: translateY(-90px) scale(0.45);
  // }
  // 100% {
  //   transform: translateY(0px) scale(1);
  // }
  // }
  
  // @keyframes dot-2-move {
  // 20% {
  //   transform: scale(1);
  // }
  // 45% {
  //   transform: translate(-16px, 12px) scale(0.45);
  // }
  // 60% {
  //   transform: translate(-80px, 60px) scale(0.45);
  // }
  // 80% {
  //   transform: translate(-80px, 60px) scale(0.45);
  // }
  // 100% {
  //   transform: translateY(0px) scale(1);
  // }
  // }
  
  // @keyframes dot-1-move {
  // 20% {
  //   transform: scale(1);
  // }
  // 45% {
  //   transform: translate(16px, 12px) scale(0.45);
  // }
  // 60% {
  //   transform: translate(80px, 60px) scale(0.45);
  // }
  // 80% {
  //   transform: translate(80px, 60px) scale(0.45);
  // }
  // 100% {
  //   transform: translateY(0px) scale(1);
  // }
  // }
  
  @keyframes rotate-move {
  0% {
    transform: translate(0%, 30%) rotate(0deg);
    
  }
  50% {
    transform: translate(0%, 30%) rotate(180deg);
    
  }
  100% {
    transform: translate(0%, 30%) rotate(360deg);
    
  }
  }
  
  // @keyframes index {
  // 0%,
  // 100% {
  //   z-index: 3;
  // }
  // 33.3% {
  //   z-index: 2;
  // }
  // 66.6% {
  //   z-index: 1;
  // }
  // }

  @keyframes index {
    0%,
    100% {
      background-color: var(--p3);
    }
    20% {
      background-color: var(--s3);
    }
    40% {
      background-color: var(--t3);
    }
    60% {
      background-color: var(--diffuse);
    }
    }
  
    .progress{
      position: absolute;
      width: 12vw;
      color: white;
      padding: 0px;
      border-radius: 30px 10px 0px 0px;
      text-align: center;
      overflow: hidden;
      left: -1vw;
      bottom: 0;
		  
			/* Apply the initial wavy top edge with clip-path */
			// clip-path: path("M0,0 Q30,10 70,20 T150,30 T250,20 T500,30 V100 H0 Z");
		}

		.wavy-box {
			// background-color: var(--ion-color-secondary);
      filter: url(#goo);
      background: linear-gradient(180deg, var(--ion-color-secondary) 0%, var(--diffuse-light-selected) 40%, var(--ion-color-primary) 60%, var(--ion-color-tertiary) 80%);
      animation: fillermain 40s ease-in-out infinite;
      background-size: 200% 250%;
      box-shadow: var(--shadow-h);
		  }

      @keyframes fillermain {
        0% {
            background-position: 50% 100%;
        }
        50% {
            background-position: 100% 250%;
        }
        100% {
            background-position: 50% 100%;
        }
    }
		  
      .wave-wrap{
        // filter: url(#too);
        position: absolute;
        overflow: hidden;
        width: 12vw;
        height: 100%;
        opacity: 1;
        bottom: 0;
        left: 0;
        
      }



      @keyframes counterpoint {
        0% {
          left: 0px;
        }
        25% {
          left: 5px;
        }
        50% {
          left: 0px;
        }
        75% {
          left: -5px;
        }
        100% {
          left: 0px;
        }
      }

      .calprogress{
        position: absolute;
        // width: 100vw;
        height: 100%;
        // transform: scaleY(1.4);
        transition: width 0.5s ease-in-out;
        color: white;
        padding: 0px;
        border-radius: 0px;
        text-align: center;
        overflow: hidden;
        bottom: 0vw;
        left: 0;
        
      }    



@keyframes pulse {
  0% {
      // stroke: var(--avg-nutri);
      stroke-width: 0.5px;
      // stroke-linecap: square; 
      // stroke-linejoin: round;
  }
  50% {
    // stroke: var(--high-nutri);
    stroke-width: 0.25px;
    // stroke-linecap: butt;
    // stroke-linejoin: bevel;
  }
  100% {
    // stroke: var(--avg-nutri);
    stroke-width: 0.5px;
    // stroke-linecap: square;
    // stroke-linejoin: round;
  }
}

.speedometer-container {
  position: relative;
  display: flex;
  gap: 2vw;
  height: 8vw;
  width: 100vw;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
}
.speedcontainer{
  // transition: stroke-dashoffset 0.5s ease-in-out, d 0.5s ease-in-out;
  // transition: d 0.4s ease-in-out, stroke 0.4s ease-in-out, fill 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
#progress-arc {
  stroke-linecap: round;
  // transition: stroke-dashoffset 0.5s ease-in-out, d 0.5s ease-in-out;
  // transition: d 0.4s ease-in-out, stroke 0.4s ease-in-out, fill 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  stroke-width:0.5px;
}

#needle {
  transition: transform 0.5s ease-in-out; /* Smooth needle rotation */
  transform-origin: 50px 15px; /* Rotation around the bottom center (50,45) */
}

#progress-arc.pulsing {
  animation: pulse 5s infinite; /* Infinite pulsing effect */
}

#path2 {
  transition: transform 0.5s ease-in-out; /* Adjust duration and timing function as needed */
  animation: counterpoint 5s ease-in-out infinite;
  // fill: #000 !important;
}
.caloriebox{
  position: absolute;
  border: 1px solid var(--diffuse-selected);
  border-radius: 15px;
}
.dailydata{
  margin: 5vw;
  contain: none;
  overflow: visible;
  // box-shadow: var(--shadow-c-l);
  background: var(--bg-light);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 90vw;
  ion-card-header {
      padding: 16px;

      ion-card-title {
          
          font-size: 24px;
          font-weight: 300;
          color: var(--ion-color-primary);
          display: flex;
          align-items: center;
          justify-content: center;
      }
  }
  // .kcalmacrolist {
  //     display: flex;
  //     flex-direction: row;
  //     transition: all 0.3s var(--ease-elastic-3);
  //     justify-content: center;
  //     z-index: 5;
  //     gap: 0vw;
  //     align-items: center;
  //     position: relative;
  //     height: 15vw;
  //     overflow: hidden;
  //     border-radius: 0px;
  //     margin: 0px;

  //     .kcal{
  //         border: 1px solid var(--kcal);
  //         justify-content: center;
  //         transition: all 0.5s var(--ease-elastic-3);
  //         will-change: contents;
  //         display: flex;
  //         gap: 0vw;
  //         align-items: center;
  //         flex-direction: column;
  //         padding: 0vw;
  //         border-radius: 0px;
  //         margin: 0vw;
  //         overflow: hidden;
  //         z-index: 99;
  //         height: 15vw;
          
  //                   .kcalname{
  //                     background: var(--kcal);
  //                     color: var(--ion-color-dark);
  //                     opacity: 1;
  //                     font-size: 14px !important;
  //                     position: relative;
  //                     transform: rotateX(0deg) translateX(0px);
  //                     font-weight: 500;
  //                     transition: all 0.5s var(--ease-elastic-3) 0.3s;
  //                     align-items: center;
  //                     justify-items: center;
  //                     will-change: contents;
  //                     display: flex;
  //                     padding: 1vw 2vw;
  //                   }
                
  //                   .kcalval {
  //                     font-size: 14px !important;
  //                     font-weight: 500;
  //                     display: flex;
  //                     padding: 1vw 2vw;
  //                     transition: all 0.3s var(--ease-elastic-3) 0.3s;
  //                     border-radius: 0px;
  //                     align-items: center;
  //                     justify-items: center;
  //                     color: var(--ion-color-dark);
  //                     z-index: 99;
  //                   }
               
  //                 }
              
    
  // } 
  .containermain{
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 auto;
      justify-content: center;
      position: relative;
      width: 90vw;
  }

  
.macrowrap{

  padding: 0;
  width: 90vw;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  position: relative;
  overflow: visible;
  display: grid;
  grid-template-columns: 75vw 15vw;
  margin: 0 auto;
  border-radius: 0;
  justify-items: center;
  align-items: center;
  align-content: center;
  // box-shadow: var(--shadow-h);
  




}
}
.macrolist {
  display: flex;
  flex-direction: row;
  transition: all 0.3s var(--ease-elastic-3);
  justify-content: space-evenly;
  z-index: 1;
  align-items: center;
  position: relative;
  margin: 0px;
  gap: 2vw;
  // bottom: 10vw;
  width: 100%;




.macro {
  transition: all 0.5s var(--ease-elastic-3);
  will-change: contents;
  display: flex;
  gap: 0vw;
  align-items: center;
  flex-direction: column;
  padding: 0vw;
  border-radius: 10px;
  margin: 0vw;
  overflow: hidden;
  width: 20vw;
 // box-shadow: 0.2rem 0.2rem 0.5rem #000 inset;


  

}
.kcal{
  border: 1px solid var(--kcal);
  .macroname{
      background: var(--kcal);
      color: #fff;
  }
}
.macrop {

border: 1px solid var(--prot);

.macroname{
background: var(--prot);
color: #fff;

}
}

.macroc {
  
border: 1px solid var(--carb);
.macroname{
background: var(--carb);

color: #fff;
}
}

.macrof {

  border: 1px solid var(--fat);


border: 1px solid var(--fat);
.macroname{
background: var(--fat);

color: #fff;
}
}

.macroname {
  opacity: 1;
  font-size: 14px !important;
  position: relative;
  transform: rotateX(0deg) translateX(0px);
  font-weight: 500;
  transition: all 0.5s var(--ease-elastic-3) 0.3s;
  align-items: center;
  justify-content: center;
  will-change: contents;
  display: flex;
  padding: 0 1vw;
  width: 100%;
  z-index: -1;
  
}

.macroval {
  font-size: 14px !important;
  font-weight: 500;
  display: flex;
  padding: 0vw 1vw;
  transition: all 0.3s var(--ease-elastic-3) 0.3s;
  border-radius: 0px;
  align-items: center;
  justify-items: center;
  color: #fff;
  z-index: 0;
  
}

    }

    @keyframes rotater {
      0%,100% {
      transform: rotate3d(0,0,0,0deg);
      }
      20% {
        transform: rotate3d(0,0,0,0deg);
      }
      86% {
        transform: rotate3d(0,1,0,0deg);
      }
      88% {
        transform: rotate3d(0,1,0,80deg);
      }
      90% {
        transform: rotate3d(0,1,0,-80deg);
      }
      92% {
        transform: rotate3d(0,1,0,0deg);
      }
      }


      .mainbox{
        display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-evenly;
      }

      .mainbox2{
        display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1vw;
      }
      
      ion-modal#datemodal {
        --background: var(--diffuse-light);
    
        &::part(content) {
            backdrop-filter: blur(6px);
        }
    
        ion-card.contentcard {
            background: var(--ion-color-light)!important ;
        --background: var(--ion-color-light)!important ;
            --padding-top: 5vh;
            --padding-start: 20px;
            --padding-end: 20px;
    ion-title{
      font-size: 18px;
    }
            ion-card {
                margin: 0px ;
                padding: 0px;
                background: var(--ion-color-light)!important ;
        --background: var(--ion-color-light)!important ;
    
                ion-card-header {
                    // border-radius: 13px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
                    ion-note {
                        // margin: 6px 0 12px;
                        font-size: 14px;
                        font-weight: 300;
                        // line-height: 21px;
                        letter-spacing: 0px;
                        color: var(--ion-color-primary);
                    }
    
                    p {
                        font-size: 12px;
                        font-weight: 300;
                        margin: 0;
                        gap: 0vw;
                        letter-spacing: 0px;
                        text-align: center;
                        color: var(--ion-color-primary);
                        display: flex;
                        flex-direction: column;
                        align-items: center;
    
                        strong {
                            font-size: 18px;
                            font-weight: 700;
                            letter-spacing: 0px;
                            color: var(--ion-color-secondary);
                        }
                    }
                }
    
                .days_set {
                    ion-button {
                        width: 45px;
                        height: 45px;
                        margin: 0 6px;
                        --border-radius: 10px;
                        color: var(--ion-color-dark);
    
                        ion-checkbox {
                            display: none;
                            width: 18px;
                            height: 18px;
                            margin: 0 5px;
                        }
                    }
                }
            }
        }
    
        ion-datetime {
            border-radius: 20px;
            width: 96%;
            margin: 2vw;
            border: 1px solid var(--ion-color-primary);
            box-shadow: var(--shadow-md);
    
          }
          ion-button.light-button {
              --color: var(--ion-color-primary);
              --background: var(--ion-color-light);
              --background-activated: var(--ion-color-light);
              --background-focused: var(--ion-color-light);
              --background-hover: var(--ion-color-light);
              border-radius: 20px;
              --border-radius: 20px;
              --padding-start: 5vw;
              --padding-end: 5vw;
                                      }
                  
              ion-button.dark-button {
              --color: var(--ion-color-light);
              color: var(--color);
              --background: var(--ion-color-primary);
              --background-activated: var(--ion-color-primary);
              --background-focused: var(--ion-color-primary);
              --background-hover: var(--ion-color-primary);
              border-radius: 20px;
              --border-radius: 20px;
              --padding-start: 5vw;
      --padding-end: 5vw;
              }
              ion-button.close-button {
                --color: var(--ion-color-dark);
                color: var(--color);
                --background: transparent;
                --background-activated: transparent;
                --background-focused: transparent;
                --background-hover: transparent;
                --border-radius: 15px;
                --padding-start: 0vw;
                --padding-end: 0vw;
                border-radius: 15px;
                box-shadow: var(--shadow-m-anim);
                margin: 2vw;
                }
     
           
        
      }
        ion-button.light-button {
            --color: var(--ion-color-primary);
            --background: var(--ion-color-light);
            --background-activated: var(--ion-color-light);
            --background-focused: var(--ion-color-light);
            --background-hover: var(--ion-color-light);
            border-radius: 20px;
            --border-radius: 20px;
            --padding-start: 5vw;
            --padding-end: 5vw;
                                    }
                
            ion-button.dark-button {
            --color: var(--ion-color-light);
            color: var(--color);
            --background: var(--ion-color-primary);
            --background-activated: var(--ion-color-primary);
            --background-focused: var(--ion-color-primary);
            --background-hover: var(--ion-color-primary);
            border-radius: 20px;
            --border-radius: 20px;
            --padding-start: 5vw;
    --padding-end: 5vw;
            }
            ion-button.close-button {
              --color: var(--ion-color-dark);
              color: var(--color);
              --background: transparent;
              --background-activated: transparent;
              --background-focused: transparent;
              --background-hover: transparent;
              --border-radius: 15px;
              --padding-start: 0vw;
              --padding-end: 0vw;
              border-radius: 15px;
              box-shadow: var(--shadow-m-anim);
              margin: 2vw;
              }
   .bg-dark{
    background: var(--ion-color-dark) !important;
    color: var(--ion-color-light) !important;
   }
   .bg-light{
    background: var(--ion-color-light) !important;
    color: var(--ion-color-dark) !important;
   }

   .headmodal{
    display: flex;
    width: 100%;
    padding: 1vw 2vw;
    margin: 0;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: var(--ion-color-light) !important;
    border-radius: 0;
    // border: 1px solid var(--diffuse);
    .titlemodal{
      font-size: 18px;
      font-weight: 400;
      width: 100%;
      text-align: center;
      // text-decoration: underline var(--ion-color-secondary) 2px;
    }
    .close-button{
      height: 8vw;
      width: 8vw;
    }
  }

  .listcard-sm {
    min-height: 15vw;
    margin-bottom: 20px;
    border-radius: 20px;
    box-shadow: var(--shadow-m);
    display: flex;
    gap: 5vw;
    font-weight: 500;
    font-size: 16px;
    white-space: nowrap;
    color: var(--ion-color-dark);
    justify-content: flex-start;
    align-items: center;
    padding: 0vw 5vw;
    background: var(--diffuse-light-selected);
   

    ion-select {
        min-width: 80% !important;
    }
}