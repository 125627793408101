/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

// Shakir custom css
@import "./theme/custom-classes.scss";
// @import "./theme/animationclasses.scss";



// *{
// --background: none;
// background: none;

// }



.contentcard{
	margin: 0;
	padding: 0;
	border-radius: 0;
	max-height: 70vh;
    overflow: auto;
}


ion-list {
	background: transparent !important;
	--background: transparent !important;
	padding: 0 10px;
	overflow: visible;
	contain: none;
}

ion-modal {
	background: #ffffff23 !important;
	--background: #ffffff23 !important;
	backdrop-filter: blur(10px);
}

ion-item {
	// background: var(--ss) !important;
	// --background: var(--ss) !important;

	background: transparent;
	--background: transparent !important;
}

input {
	background: none !important;
	// backdrop-filter: blur(10px);
}

ion-button {
	--background: var(--ion-color-primary);
	//background: var(--ion-color-primary);
    --background-activated: var(--ion-color-primary);
    --background-focused: var(--ion-color-primary);
    --background-hover: var(--ion-color-primary);
}

ion-header {
	min-height: env(safe-area-inset-top) !important;
}

.alert-title{
	color: var(--ion-color-dark) !important;
}
.alert-radio-label{
	color: var(--ion-color-dark) !important;
}
.alert-wrapper{
	background: var(--ion-color-light) !important;
	box-shadow: var(--shadow-h) !important;
}
.alert-message{
	color: var(--ion-color-dark) !important;
}

	:host .calendar-action-buttons ion-item{
	--color: var(--ion-color-dark) !important;
}

ion-header,
ion-footer {
	background: transparent !important;
	--background: transparent !important;

	ion-toolbar {
		//--background: transparent;
		--border-width: 0;
		--padding-start: 20px;
		--padding-end: 20px;
		background: transparent !important;
		--background: transparent !important;
		//min-height: 5vh;
		// --ion-safe-area-top: env(safe-area-inset-top);

		ion-title {
			font-weight: 300;
			font-size: 30px;
			//line-height: 40.5px;
		}

		ion-back-button {
			margin: 0 auto;
			font-size: 18px;
		}
	}
}

ion-popover {
	&::part(content) {
		min-width: 97vw;
		max-height: 135px;
	}
}


ion-select-popover {
	display: contents;
}

ion-content {
	// background: var(--ion-color-light);
	background-image: transparent !important;
	background: transparent !important;
	--background: transparent !important;
	background-color: #fff;

	background-repeat: no-repeat !important;
	background-position: bottom !important;
	background-size: cover !important;
// 	background: var(--diffuse-light) !important;
// --background: var(--diffuse-light) !important;

}

// --background: url("assets/images/background.png") 0 0/100% 100% no-repeat;


.margin-ios {
	margin-top: 16px;
}

.subsciption-slides .swiper-pagination-bullet {
	width: 15px;
	height: 15px;
}

ul.rating {
	margin: 0 5px !important;
	padding: 0;

	li {
		padding: 0.5px !important;
		font-size: 14px;
	}
}

ion-icon[name="star"] {
	color: var(--ion-color-warning);
}


:root[dir="ltr"] {
	// .double-box {
	// 	position: absolute;
	// 	right: 20px;
	// 	top: 6px;
	// }
	.food{
		left: 10px;
	}
	@keyframes cooking {
		0% {
		  transform: rotate(0deg);
		  transform-origin: top right;
		}
		10% {
		  transform: rotate(-4deg);
		  transform-origin: top right;
		}
		50% {
		  transform: rotate(20deg);
		}
		100% {
		  transform: rotate(0deg);
		}
	  }
	  .panWrapper {
		align-items: flex-start;
		justify-content: flex-end;
	  }
	  .pan{
		align-items: flex-start;
		justify-content: flex-start;
	  }
	  .panshadow{
		margin-left: 15px;
	  }
	.mcount{
		flex-direction: column;
	}
	.scount{
		flex-direction: column;
	}
	.explore-col {
		position: absolute;
		right: 0;
		bottom: 0;
	}

	ion-icon.arrow-forward-prev {
		position: absolute;
		left: -11px;
		top: 50px;
		z-index: 999;
	}

	ion-icon.arrow-forward-next {
		position: absolute;
		right: -11px;
		top: 50px;
		z-index: 999;
	}
	.rar{
		left: 0;
		}
		.lar{
		right: 0;
	  
		}
	.freeze{
		ion-icon{
			right: 0;
		}
	}
	.biobox, .backbox{

		width: 45%;
		left: 5vw;
	}
	.bigbox{
	left: 5vw;
	}
	.signinbox{

		width: 125%;
		right: 5vw;
	
	}
	
	.bigboxf{
		left: 5vw;
		width: 90vw;
	}

	ion-chip.menu-price {
		position: absolute;
		top: 6px;
		right: 0;
	}

	ion-badge.replaceable-close {
		position: absolute;
		right: 16px;
		top: 0;
		z-index: 999;
		border-radius: 50%;
		height: 24px;
		width: 24px;
		padding: 2px;

		ion-icon {
			font-size: 20px;
		}
	}

	ion-card.smallerone {
		ion-radio-group {
			ion-item.before-active {
				// border-radius: 0px 25px 25px 0 !important;
				margin-right: 0.25vh !important;
			}

			ion-item.after-active {
				// border-radius: 25px 0px 0px 25px !important;
				margin-left: 0.25vh !important;
			}
		}
	}

	ion-badge.notify {
		position: absolute;
		top: 10vw;
		left: 7vw;
	}

	ion-card.calendar {
		ion-icon[name="chevron-back"] {
			position: absolute;
			top: 35px;
			left: 0;
			z-index: 999;
			overflow: hidden;
		}

		ion-icon[name="chevron-forward"] {
			position: absolute;
			top: 35px;
			right: 0;
			z-index: 999;
			overflow: hidden;
		}
	}

	.button-icon {
		position: absolute;
		right: 0;
	}

	ion-select::part(icon) {
		position: absolute;
		right: 5px;
		opacity: 1;
		color: var(--ion-color-primary);
	}

	ion-icon.next-item {
		position: absolute;
		top: 20px;
		right: 0;
	}

	ion-icon[name="expand"] {
		position: absolute;
		left: 85px;
		bottom: 6px;
		font-size: 20px;
	}

	ion-fab-list.contact {
		height: 100%;
		// margin: 0 30vw;
	}

	// ion-button.close-double-box {
	// 	position: fixed;
	// 	left: 85%;
	// }

	// ion-button.close-single-box {
	// 	position: fixed;
	// 	left: 46%;
	// }

	ion-button.expand-button {
		position: absolute;
		bottom: 25%;
		right: 0;
		background: var(--diffuse-light);
		--background: var(--diffuse-light);
	}

	ion-icon.arrow-forward1 {
		position: relative;
		bottom: 145px;
		left: 85vw;
		z-index: 999;
		overflow: hidden;
	}

	ion-icon.arrow-forward2 {
		position: relative;
		bottom: 145px;
		left: 80vw;
		z-index: 999;
		overflow: hidden;
	}

	ion-icon.arrow-forward3 {
		position: relative;
		bottom: 145px;
		left: 75vw;
		z-index: 999;
		overflow: hidden;
	}

	div.whatsapp-bottom {
		position: absolute !important;
		right: 10px;
	}


.tiltico{
	right: -2vw;
}
				




		}
		
		:root[dir="rtl"] {
	*{
		letter-spacing: normal !important;
	}

	.food{
		right: 10px;
	}
	@keyframes cooking {
		0% {
		  transform: rotate(0deg);
		  transform-origin: top left;
		}
		10% {
		  transform: rotate(-4deg);
		  transform-origin: top left;
		}
		50% {
		  transform: rotate(20deg);
		}
		100% {
		  transform: rotate(0deg);
		}
	  }
	  .panshadow{
		margin-right: 15px;
	  }
	  .panWrapper {
		align-items: flex-start;
		justify-content: flex-end;
	  }
	  .pan{
		align-items: flex-start;
		justify-content: flex-start;
	  }
	.tiltico{
		left: -2vw;
	}
	// .double-box {
	// 	position: absolute;
	// 	left: 20px;
	// 	top: 6px;
	// }

	.scount{
		flex-direction: column;
	}
	.mcount{
		flex-direction: column;
	}
	.explore-col {
		position: absolute;
		left: 0;
		bottom: 0;
	}

	ion-icon.arrow-forward-prev {
		position: absolute;
		left: -11px;
		top: 50px;
		z-index: 999;
	}

	ion-icon.arrow-forward-next {
		position: absolute;
		right: -11px;
		top: 50px;
		z-index: 999;
	}
	.rar{
		left: 0;
		}
		.lar{
		right: 0;
	  
		}

.freeze{
	ion-icon{
		left: 0;
	}
}
	.biobox, .backbox{

		width: 45%;
		right: 5vw;
	}
	.bigbox{
	right: 5vw;
	}
	.signinbox{

		width: 125%;
		left: 5vw;
	
	}
	
	.bigboxf{
		right: 5vw;
		width: 90vw;
	}

	ion-chip.menu-price {
		position: absolute;
		top: 6px;
		left: 0;
	}

	ion-badge.replaceable-close {
		position: absolute;
		right: 16px;
		top: 0;
		z-index: 999;
		border-radius: 50%;
		height: 24px;
		width: 24px;
		padding: 2px;

		ion-icon {
			font-size: 20px;
		}
	}

	ion-badge.notify {
	    position: absolute;
    top: 10vw;
    left: 7vw;
	}

	ion-fab-list.contact {
		// margin: 0 30vw;
		height: 100%;
	}

	// ion-button.close-double-box {
	// 	position: fixed;
	// 	right: 46%;
	// }

	// ion-button.close-single-box {

	// 	position: fixed;
	// 	right: 85%;
	// }

	ion-card.calendar {
		ion-icon[name="chevron-back"] {
			position: absolute;
			top: 35px;
			right: 0;
			z-index: 999;
			overflow: hidden;
		}

		ion-icon[name="chevron-forward"] {
			position: absolute;
			top: 35px;
			left: 0;
			z-index: 999;
			overflow: hidden;
		}
	}

	ion-card.smallerone {
		ion-radio-group {
			ion-item.before-active {
				// border-radius: 25px 0px 0px 25px !important;
				margin-left: 0.25vh !important;
			}

			ion-item.after-active {

				// border-radius: 0px 25px 25px 0 !important;
				margin-right: 0.25vh !important;
			}
		}
	}

	.button-icon {
		position: absolute;
		left: 0;
	}

	ion-select::part(icon) {
		position: absolute;
		left: 30px;
		opacity: 1;
		color: var(--ion-color-primary);
	}

	ion-icon.next-item {
		position: absolute;
		top: 20px;
		left: 0;
	}

	ion-icon[name="expand"] {
		position: absolute;
		right: 85px;
		bottom: 6px;
		font-size: 20px;
	}

	ion-button.expand-button {
		position: absolute;
		bottom: 25%;
		left: 0;
		--background: var(--diffuse-light);
		background: var(--diffuse-light);
	}

	ion-icon.arrow-forward1 {
		position: relative;
		bottom: 145px;
		right: 85vw;
		z-index: 999;
		overflow: hidden;
	}

	ion-icon.arrow-forward2 {
		position: relative;
		bottom: 145px;
		right: 80vw;
		z-index: 999;
		overflow: hidden;
	}

	ion-icon.arrow-forward3 {
		position: relative;
		bottom: 145px;
		right: 75vw;
		z-index: 999;
		overflow: hidden;
	}

	div.whatsapp-bottom {
		position: absolute !important;
		left: 10px;
	}
}

ion-card {
	box-shadow: none;

	ion-label.app-label {
		font-size: 20px;
		font-weight: 500;
		line-height: 24px;
		color: var(--ion-color-dark);
	}

	ion-item.input-item,.lang,.set {
		--min-height: 10vw;
		margin-bottom: 0;
		border-radius: 10px;
		box-shadow: var(--shadow-m-anim);
		display: flex;
		font-weight: 500;
		font-size: 16px;
		white-space: nowrap;
		color: var(--ion-color-dark);


		ion-input.app-input {
			min-height: 52px;
			--border-radius: 0;
			--placeholder-color: var(--ion-color-medium);
			--placeholder-background:none;
			background: none;
			--background: none;
			color: var(--ion-color-primary) !important;

			input {
				color: var(--ion-color-primary) !important;
			}
		}
	}

	ion-button.app-button {
		--border-radius: 32px;
		min-height: 32px;
		font-size: 20px;
		--background: var(--ion-color-primary);
		--background-activated: var(--ion-color-primary);
		--box-shadow: var(--shadow-h);
	}
}

ion-icon[name="eye-close"] {
	font-size: 20px;
}

ion-tab-bar {
    --border: none;
    padding: 0vw 5vw 2vw;
    height: 20vw;
    background: rgba(255, 255, 255, 0) !important;
    transition: all 0.3s ease-in-out;
    width: 90vw;
    margin: 0;

	ion-tab-button::part(native) {
		overflow: visible !important;
		contain: none;
	}


	ion-tab-button {
		// padding-top: 5px;
		background: #fff0 !important;
		color: var(--ion-color-dark);
		transition: all 0.3s ease-in-out;
		overflow: visible;
		contain: none;
		--overflow: visible;
		

		ion-label {
			margin-top: 0px;
			font-size: 11px;
			font-weight: 500;
			line-height: 16px;
			letter-spacing: 0em;
			color: #484c52;
			display: none;
			height: 0;
			width: 0;
		}
		ion-icon{
			scale: 0.8;
			stroke:var(--ion-color-medium) !important;
			fill: var(--ion-color-primary);
			transition: all 0.3s ease-in-out;
			z-index: 1;
			background: transparent;
			border: 0px solid var(--ion-color-secondary);	
			padding: 1.5vw;
			margin: 1.5vw;
			border-radius: 10px;
			box-shadow: 0 0 0 0 black;
		}
	}

	ion-tab-button.tab-selected {
		color: var(--highlight);
		border-radius: 10px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		--padding-end: 0;
		--padding-start: 0;
		
		ion-icon{
			stroke: var(--ion-color-secondary) !important;
			fill: var(--ion-color-primary);
			transition: all 0.3s ease-in-out;
			scale: 1;
			background: transparent;
			border: 0px solid var(--ion-color-secondary);	
			padding: 1.5vw;
			margin: 1.5vw;
			border-radius: 10px;
			box-shadow: var(--shadow-m-small);
		}

		ion-label {
			color: var(--p3);
		}
	}

	/*ion-tab-button ion-icon[name="calendar-outline"] {
		content: url("assets/svg/calendar-outline.svg");
		font-size: 24px;
		color: var(--ion-color-primary);
	}

	ion-tab-button.tab-selected ion-icon[name="calendar-outline"] {
		content: url("assets/svg/calendar.svg");
		font-size: 24px;
		color: var(--ion-color-primary);
	}

	ion-tab-button ion-icon[name="cart-outline"] {
		content: url("assets/svg/order.svg");
		font-size: 24px;
	}

	ion-tab-button.tab-selected ion-icon[name="cart-outline"] {
		content: url("assets/svg/order-selected.svg");
		font-size: 24px;
	}

	ion-tab-button ion-icon[name="home-outline"] {
		content: url("assets/svg/menu.svg");
		font-size: 24px;
	}

	ion-tab-button.tab-selected ion-icon[name="home-outline"] {
		content: url("assets/svg/menu-selected.svg");
		font-size: 24px;
	}

	ion-tab-button ion-icon[name="notifications-outline"] {
		content: url("assets/svg/notifications.svg");
		font-size: 24px;
	}

	ion-tab-button.tab-selected ion-icon[name="notifications-outline"] {
		content: url("assets/svg/notifications-selected.svg");
		font-size: 24px;
	}

	ion-tab-button ion-icon[name="star-outline"] {
		content: url("assets/svg/star.svg");
		font-size: 24px;
	}

	ion-tab-button.tab-selected ion-icon[name="star-outline"] {
		content: url("assets/svg/star-selected.svg");
		font-size: 24px;
	}

	ion-tab-button ion-icon[name="person-add-outline"] {
		content: url("assets/svg/accounts.svg");
		font-size: 24px;
	}

	ion-tab-button.tab-selected ion-icon[name="person-add-outline"] {
		content: url("assets/svg/accounts-selected.svg");
		font-size: 24px;
	}*/
}

ion-modal.rating-modal-css {
	margin: 0 auto;
	--width: 90%;
	--height: 78%;
	--border-radius: 20px;
	// --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
		// 0 4px 6px -4px rgb(0 0 0 / 0.1);
	--background: rgba(44, 39, 45, 0.2);

	app-rating {
		border-radius: 20px;
		border: 2px solid var(--ion-color-primary);
	}
}

ion-modal.small-modal {
	--height: 20em;
	border-radius: 20px;

	app-coupon-modal {
		border-radius: 20px;
		margin: 6px;
	}
}

ion-modal.basic-modal::part(backdrop) {
	display: none;
}

ion-modal.basic-modal::part(content) {
	backdrop-filter: blur(20px);
	overflow: hidden;
	--overflow: hidden;
	border: 0px solid black;
	border-radius: 20px;
	margin: 10px;
}

ion-modal.app-citation-modal::part(content) {
	overflow: visible !important;
	--overflow: visible !important;
}

ion-modal.basic-modal {
	--height: fit-content;
	// --background: #ffffff24 !important;
	--background: #ffffff00 !important;
	// --backdrop-opacity: 0;


	app-comment-modal {
		height: 95% !important;
		border-radius: 20px;
		margin: 6px;
		border: 2px solid var(--ion-color-primary);
	}

	app-login-modal {
		--background: #00000041 !important;

		// height: 75% !important;
		// border-radius: 20px;

	}

	app-language-modal {
		height: 95% !important;
		border-radius: 20px;
		margin: 6px;
		border: 2px solid var(--ion-color-primary);
	}
}

ion-modal.basic-modal-big {
	--height: 100vh;
	--background: #ffffff00 !important;

	app-citation-modal {
		height: 95% !important;
		--height: 95% !important;
		border-radius: 20px;

	}

}

ion-modal.show-modal::part(backdrop){
	display: none;
	}
	
	ion-modal.show-modal::part(content){
	backdrop-filter: blur(10px);
	overflow: hidden;
	--overflow: hidden;
	border: 0px solid black;
	border-radius: 20px;
	margin: 10px;
	}
	
	ion-modal.show-modal {
		--height: fit-content;
		// --background: #ffffff24 !important;
		--background: #ffffff00 !important;
		--box-shadow: var(--shadow-h) !important;
		// --backdrop-opacity: 0;
		--border-radius: 25px;
    --width: 90vw;

	}
#datemodal3{
	--height: 85%;
}

#datemodal{
	// height: 100%;
}
#datemodal2{
	--height: 35%;
}
// ion-modal.show-modal {
//     --height: 65%;
//     --border-radius: 17px;
//     --width: 90vw;
//     // --box-shadow: var(--shadow-diffuse);
//     --backdrop-opacity: 0;
// }
ion-modal.terms-modal {
	--height: 75%;
	--border-radius: 20px;

	app-post-modal {
		height: 95% !important;
		border-radius: 20px;
		margin: 6px;
		border: 2px solid var(--ion-color-primary);
	}

	app-terms-and-conditions {
		border-radius: 20px;
		border: 2px solid var(--ion-color-primary);
	}

	app-subscription-detail {
		border-radius: 20px;
		border: 2px solid var(--ion-color-primary);
	}

	app-menu-detail {
		border-radius: 20px;
		border: 2px solid var(--ion-color-primary);
	}

	app-menu-replace {
		border-radius: 20px;
		border: 2px solid var(--ion-color-primary);
	}
}

ion-modal.terms-modal::part(backdrop) {
	background: rgb(255 252 252);
	opacity: 1;
}

ion-buttons.close-button {
	position: relative;
	background: var(--ion-color-primary);
	color: var(--ion-color-light);
	border-radius: 20px;
}

.toast-wrapper {
	width: max-content;
	text-align: center;
	background: var(--p3);
	border: 3px solid var(--p3);
}



ion-datetime::part(calendar-day) {
	color: #da5296;
}

ion-datetime::part(calendar-day today) {
	color: #8462d1;
}

ion-datetime::part(calendar-day):focus {
	background-color: rgb(154 209 98 / 0.2);
	box-shadow: 0px 0px 0px 4px rgb(154 209 98 / 0.2);
}

/*
  * Custom Material Design Datetime Day Parts
  * -------------------------------------------
  */

ion-datetime.md::part(calendar-day active),
ion-datetime.md::part(calendar-day active):focus {
	background-color: #9ad162;
	border-color: #9ad162;
	color: #fff;
}

ion-datetime.md::part(calendar-day today) {
	border-color: #8462d1;
}

/*
  * Custom iOS Datetime Day Parts
  * -------------------------------------------
  */

ion-datetime.ios::part(calendar-day active),
ion-datetime.ios::part(calendar-day active):focus {
	background-color: rgb(154 209 98 / 0.2);
	color: #9ad162;
}

.modal-enter, .modal-leave {
	transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  }
  
  .modal-enter {
	transform: translateY(0);
	opacity: 1;
  }
  
  .modal-leave {
	transform: translateY(100%);
	opacity: 0;
  }

  ion-datetime.condate::part(month-header) {
    color: #ff5733; /* Replace with your desired color */
  }
// @media only screen and (min-height: 600px) and (min-width: 768px) {
// 	.show-modal {
// 		--width: 100% !important;
// 		--height: 100% !important;
// 	}

// 	.ion-page {
// 		max-height: 97vh;
// 		width: 80vw;
// 		margin: 0 auto !important;

// 		ion-content {
// 			.no-menu {
// 				padding-top: 10% !important;
// 			}

// 			.calendar-bottom {
// 				margin-top: 0 !important;
// 			}

// 			.message {
// 				margin-top: 0 !important;
// 			}
			
// 		}

// 		ion-tab-bar {
// 			height: 100px;
// 			padding: 0px !important;
// 		}
// 	}
// }

// @media screen and (min-height: 600px) and (min-width: 768px) {
// 	body {
// 		background-color: #eee;
// 	}

// 	.ion-page {
// 		max-height: 97vh;
// 		width: 80vw;
// 		margin: 0 auto !important;

// 		ion-content {
// 			.no-menu {
// 				padding-top: 10% !important;
// 			}

// 			.calendar-bottom {
// 				margin-top: 0 !important;
// 			}

// 			.message {
// 				margin-top: 0 !important;
// 			}			
// 		}

// 		ion-tab-bar {
// 			height: 100px;
// 			padding: 0px !important;
// 		}
// 	}
// }